import * as TYPE from "../constant/point_type";

var points = [
  {
    name: "MOS 漢堡 福林店",
    lat: 25.096987,
    lng: 121.532516,
    type: TYPE.FOOD,
  },
  {
    name: "MOS 漢堡 士林捷運店",
    lat: 25.095292,
    lng: 121.526259,
    type: TYPE.FOOD,
  },
  {
    name: "喫茶趣",
    lat: 25.095131,
    lng: 121.528376,
    type: TYPE.FOOD,
  },
  {
    name: "堤諾義式比薩",
    lat: 25.095324,
    lng: 121.526627,
    type: TYPE.FOOD,
  },
  {
    name: "星巴克",
    lat: 25.095172,
    lng: 121.525879,
    type: TYPE.FOOD,
  },
  {
    name: "永和豆漿大王",
    lat: 25.095288,
    lng: 121.5265,
    type: TYPE.FOOD,
  },
  {
    name: "洋蔥 牛排館",
    lat: 25.096091,
    lng: 121.528421,
    type: TYPE.RESTAURANT,
  },
  {
    name: "越香蘭越式料理",
    lat: 25.096282,
    lng: 121.528124,
    type: TYPE.RESTAURANT,
  },
  {
    name: "雨農小館",
    lat: 25.099387,
    lng: 121.532364,
    type: TYPE.RESTAURANT,
  },
  {
    name: "吉祥小館",
    lat: 25.098915,
    lng: 121.532241,
    type: TYPE.RESTAURANT,
  },
  {
    name: "雞當家雨農店",
    lat: 25.098007,
    lng: 121.532149,
    type: TYPE.RESTAURANT,
  },
  {
    name: "花蓮扁食玉里橋頭麵",
    lat: 25.097674,
    lng: 121.532063,
    type: TYPE.RESTAURANT,
  },

  {
    name: "燦坤3C-士林旗艦店",
    lat: 25.095827,
    lng: 121.529152,
    type: TYPE.SHOPPING,
  },
  {
    name: "全國電子文林（士林店）",
    lat: 25.09538,
    lng: 121.524654,
    type: TYPE.SHOPPING,
  },
  {
    name: "屈臣氏+士捷",
    lat: 25.095152,
    lng: 121.525799,
    type: TYPE.SHOPPING,
  },
  // {
  //   name: "台塑加油站",
  //   lat: 25.095572,
  //   lng: 121.528322,
  //   type: TYPE.SHOPPING,
  // },
  {
    name: "全聯福利中心士林中正店",
    lat: 25.095683,
    lng: 121.528597,
    type: TYPE.SHOPPING,
  },
  {
    name: "華榮街",
    lat: 25.096099,
    lng: 121.523437,
    type: TYPE.SHOPPING,
  },

  {
    name: "聯合醫院陽明院區",
    lat: 25.10529,
    lng: 121.532179,
    type: TYPE.MEDICAL,
  },
  {
    name: "新光吳火獅紀念醫院",
    lat: 25.096069,
    lng: 121.51998,
    type: TYPE.MEDICAL,
  },
  {
    name: "臺北榮民總醫院",
    lat: 25.119767,
    lng: 121.520148,
    type: TYPE.MEDICAL,
  },
  {
    name: "振興醫院",
    lat: 25.116244,
    lng: 121.522913,
    type: TYPE.MEDICAL,
  },

  {
    name: "忠誠公園",
    lat: 25.103565,
    lng: 121.525774,
    type: TYPE.GREEN,
  },
  {
    name: "芝山岩",
    lat: 25.103916,
    lng: 121.530274,
    type: TYPE.GREEN,
  },
  {
    name: "福林公園",
    lat: 25.094818,
    lng: 121.529033,
    type: TYPE.GREEN,
  },
  {
    name: "福志公園",
    lat: 25.099247,
    lng: 121.534305,
    type: TYPE.GREEN,
  },
  {
    name: "雙溪河濱公園",
    lat: 25.09993,
    lng: 121.528026,
    type: TYPE.GREEN,
  },
  {
    name: "至善公園",
    lat: 25.098603,
    lng: 121.537563,
    type: TYPE.GREEN,
  },
  {
    name: "雙溪公園",
    lat: 25.098496,
    lng: 121.536105,
    type: TYPE.GREEN,
  },
  {
    name: "志成公園",
    lat: 25.096616,
    lng: 121.530867,
    type: TYPE.GREEN,
  },
  {
    name: "老地方觀景平台",
    lat: 25.090443,
    lng: 121.536783,
    type: TYPE.GREEN,
  },
  {
    name: "203號公園",
    lat: 25.091958,
    lng: 121.528452,
    type: TYPE.GREEN,
  },

  {
    name: "雨聲國小",
    lat: 25.102633,
    lng: 121.535266,
    type: TYPE.SCHOOL,
  },
  {
    name: "雨農國民小學",
    lat: 25.105034,
    lng: 121.530195,
    type: TYPE.SCHOOL,
  },
  {
    name: "文昌國小",
    lat: 25.099417,
    lng: 121.521334,
    type: TYPE.SCHOOL,
  },
  {
    name: "私立華興高級中學",
    lat: 25.105088,
    lng: 121.538522,
    type: TYPE.SCHOOL,
  },
  {
    name: "福林國小",
    lat: 25.098203,
    lng: 121.529856,
    type: TYPE.SCHOOL,
  },
  {
    name: "泰北高中",
    lat: 25.097386,
    lng: 121.533895,
    type: TYPE.SCHOOL,
  },
  {
    name: "士林國中",
    lat: 25.094417,
    lng: 121.522629,
    type: TYPE.SCHOOL,
  },
  {
    name: "士林國民小學",
    lat: 25.093331,
    lng: 121.524925,
    type: TYPE.SCHOOL,
  },
];

export default points;
