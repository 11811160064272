import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Link from "./LinkWithTransitionLock";
import { isMobile } from "mobile-device-detect";
import Helmet from "react-helmet";

import { Slide, Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// import "splitting/dist/splitting.css";
// import "splitting/dist/splitting-cells.css";
// import Splitting from "splitting";

import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power3,
  Power4,
  Expo,
  gsap,
} from "gsap";

import SelectBuilding from "./SelectBuilding";
import g from "gsap-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  canSwipe: true,
  indicators: false,
  prevArrow: (
    <div className="slide_nav prev">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="arrow"
        fill="#fff"
      >
        <path d="M5.25514 11.0138H15.7106L10.9124 6.21565L12.1281 5L19.001 11.8729L12.1281 18.7458L10.9124 17.5302L15.7106 12.732H5.25514V11.0138Z"></path>
      </svg>
    </div>
  ),
  nextArrow: (
    <div className="slide_nav next">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="arrow"
        fill="#fff"
      >
        <path d="M5.25514 11.0138H15.7106L10.9124 6.21565L12.1281 5L19.001 11.8729L12.1281 18.7458L10.9124 17.5302L15.7106 12.732H5.25514V11.0138Z"></path>
      </svg>
    </div>
  ),
  // indicators: (i) => <div className="indicator">{i + 1}</div>,
};

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    var header = document.querySelector(".header");
    header.classList.remove("hide");
    document.querySelector("body").classList.remove("overflow");

    var window_width = window.innerWidth;
    var window_height = window.innerHeight;
    var select_floor = document.querySelector(".select_floor");
    var page_archi_content = document.querySelector(".page_archi_content");
    var featured_hero = document.querySelector(".featured_hero");
    featured_hero.style.height = window_height + "px";

    page_archi_content.style.marginTop = window_height + "px";

    if (window.location.hash === "#freespace") {
      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector("#freespace").offsetTop + window_height,
          behavior: "smooth",
        });
      }, 2000);
    }
    if (window.location.hash === "#lighting_video") {
      // gsap.set(".featured_hero", {
      //   ease: "none",
      //   immediateRender: false,
      //   zIndex: 1,
      // });
      // gsap.set(".lighting_video", {
      //   ease: "none",
      //   immediateRender: false,
      //   zIndex: 2,
      // });
      // gsap.set(".select_floor", {
      //   ease: "none",
      //   immediateRender: false,
      //   zIndex: 2,
      // });

      // gsap.set(".featured_hero", {
      //   ease: "none",
      //   scrollTrigger: {
      //     trigger: ".freespace",
      //     scrub: true,
      //   },
      //   immediateRender: false,
      //   zIndex: 1,
      // });
      gsap.set(".lighting_video", {
        ease: "none",
        scrollTrigger: {
          trigger: ".freespace",
          scrub: true,
        },
        immediateRender: false,
        zIndex: 2,
      });
      gsap.set(".select_floor", {
        ease: "none",
        scrollTrigger: {
          trigger: ".lighting_building",
          scrub: true,
        },
        immediateRender: false,
        zIndex: 2,
      });

      setTimeout(() => {
        window.scrollTo({
          top: document.querySelector("#lighting_video").offsetTop,
          behavior: "smooth",
        });
      }, 1000);
    }

    // gsap.from(".featured_slogan", {
    //   y: "20%",
    //   opacity: 0,
    //   duration: 2,
    //   ease: Power4.easeOut,
    //   delay: 0.5,
    // });

    TweenMax.staggerFrom(
      ".featured_hero .text span",
      1.2,
      {
        opacity: 0,
        y: "50%",
        ease: Power2.easeOut,
      },
      0.08
    );

    // gsap.from(".featured_slogan .text_a", {
    //   y: "20%",
    //   opacity: 0,
    //   duration: 1,
    //   ease: Power4.easeOut,
    //   delay: 0.8,
    // });
    // gsap.from(".featured_slogan .text_b", {
    //   y: "20%",
    //   opacity: 0,
    //   duration: 1,
    //   ease: Power4.easeOut,
    //   delay: 1,
    // });

    gsap.to(".featured_hero", {
      yPercent: -20,
      // opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".page_archi_content",
        start: "top bottom",
        end: "center top",
        scrub: true,
      },
    });
    gsap.to(".freespace", {
      y: 0,
      // opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".page_archi_content",
        start: "top bottom",
        end: "center top",
        scrub: true,
      },
    });

    // gsap.set(".featured_hero", {
    //   ease: "none",
    //   scrollTrigger: {
    //     trigger: ".freespace",
    //     scrub: true,
    //   },
    //   immediateRender: false,
    //   zIndex: 1,
    // });
    gsap.set(".lighting_video", {
      ease: "none",
      scrollTrigger: {
        trigger: ".freespace",
        scrub: true,
      },
      immediateRender: false,
      zIndex: 2,
    });
    gsap.set(".select_floor", {
      ease: "none",
      scrollTrigger: {
        trigger: ".lighting_building",
        scrub: true,
      },
      immediateRender: false,
      zIndex: 2,
    });

    // gsap.to(".freespace .inner_cover .photo", {
    //   backgroundPositionY: `${window.innerHeight * 0.2}px`,
    //   ease: "none",
    //   scrollTrigger: {
    //     trigger: ".freespace .inner_cover .photo",
    //     scrub: true,
    //     // markers: true,
    //   },
    // });

    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
      var mql = window.matchMedia(mediaQuery);
      mql.addListener(function(e) {
        return layoutChangedCallback(e.matches);
      });
      layoutChangedCallback(mql.matches);
    }
    installMediaQueryWatcher("(min-width: 767px)", function(matches) {
      if (matches) {
        gsap.to(".lighting_animation", {
          y: "0%",
          ease: "none",
          scrollTrigger: {
            trigger: ".lighting_video",
            scrub: true,
          },
        });
        gsap.to(".window_animation", {
          left: "25%",
          opacity: 1,
          duration: 5,
          ease: Expo.easeOut,
        });
      } else {
        gsap.to(".lighting_animation", {
          y: "-20%",
          ease: "none",
          scrollTrigger: {
            trigger: ".lighting_video",
            scrub: true,
          },
        });
        gsap.to(".window_animation", {
          left: "-100%",
          opacity: 1,
          duration: 5,
          ease: Expo.easeOut,
        });
      }
    });

    // var tl1 = new TimelineMax({ repeat: -1, repeatDelay: 4 });
    // tl1
    //   .to(".photo_b", {
    //     opacity: 0,
    //     ease: Power1.easeOut,
    //     duration: 2,
    //   })
    //   .to(".photo_b", {
    //     opacity: 1,
    //     ease: Power1.easeOut,
    //     duration: 2,
    //     delay: 4,
    //   });

    setTimeout(() => {
      // gsap.utils.toArray(".freespace").forEach((section) => {
      //   let tl = new TimelineMax({
      //     scrollTrigger: {
      //       trigger: section,
      //       start: "top top",
      //       // makes the height of the scrolling (while pinning) match the width, thus the speed remains constant (vertical/horizontal)
      //       end: () => "+=" + section.offsetWidth,
      //       scrub: true,
      //       pin: true,
      //       anticipatePin: 1,
      //     },
      //     defaults: { ease: "none" },
      //   });
      //   tl.fromTo(
      //     section.querySelector(".photo_b"),
      //     { width: 0 },
      //     { width: window.innerWidth }
      //   );
      //   // tl.fromTo(
      //   //   section.querySelector(".photo_b img"),
      //   //   { x: 0 },
      //   //   { x: -window.innerWidth }
      //   // );
      // });

      gsap.to(".select_floor .building_photo", {
        scrollTrigger: {
          trigger: ".construction",
          start: "top",
          end: "+=" + window.innerHeight,
          scrub: true,
          // markers: true,
        },
        opacity: 1,
      });
    }, 1000);

    const switch_btn = document.querySelectorAll(".switch_btn");
    const lighting_building = document.querySelector(".lighting_building");

    switch_btn.forEach(function(btn) {
      btn.addEventListener("click", function() {
        if (switch_btn[0].classList.contains("active")) {
          console.log("0");
          switch_btn[0].classList.remove("active");
          switch_btn[1].classList.add("active");
          lighting_building.classList.remove("lighting_active");
          lighting_building.classList.remove("afterenter");
        } else {
          console.log("1");
          switch_btn[0].classList.add("active");
          switch_btn[1].classList.remove("active");
          lighting_building.classList.add("lighting_active");
          lighting_building.classList.remove("afterenter");
        }
      });
    });

    window.addEventListener("scroll", function() {
      var section = document.querySelectorAll("section");
      section.forEach(function(element) {
        if (element.getBoundingClientRect().y - window_height * 0.6 < 0) {
          element.classList.add("enter");
          lighting_building.classList.remove("lighting_active");
          switch_btn[0].classList.add("active");
          switch_btn[1].classList.remove("active");
          setTimeout(() => {
            element.classList.add("afterenter");
          }, 800);
        } else {
          element.classList.remove("enter");
          lighting_building.classList.remove("lighting_active");
          switch_btn[0].classList.add("active");
          switch_btn[1].classList.remove("active");
          // element.classList.remove("afterenter");
        }
      });

      if (document.querySelector(".construction") != null) {
        if (
          document.querySelector(".construction").classList.contains("enter")
        ) {
          document.querySelector(".select_floor").classList.add("available");
        } else {
          document.querySelector(".select_floor").classList.remove("available");
        }
      }

      if (window.pageYOffset <= window.innerHeight) {
        gsap.set(".featured_hero", {
          ease: "none",
          immediateRender: false,
          zIndex: 3,
        });
      } else {
        gsap.set(".featured_hero", {
          ease: "none",
          immediateRender: false,
          zIndex: 1,
        });
      }
    });

    gsap.to(".select_floor", {
      scrollTrigger: {
        trigger: ".construction",
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
      top: "0%",
    });
    select_floor.style.zIndex = 0;
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <section className="featured_hero page_archi full_width">
          <div className="section_container featured_slogan">
            <h3>
              <div className="text text_a">
                <span>不</span>
                <span>被</span>
                <span>鄰</span>
                <span>居</span>
                <span>所</span>
                <span>擾</span>
              </div>
              <div className="text text_b">
                <span>獨</span>
                <span>自</span>
                <span>坐</span>
                <span>擁</span>
                <span>大</span>
                <span>器</span>
                <span>視</span>
                <span>野</span>
              </div>
            </h3>
          </div>
          <video
            className="window_animation"
            loop
            autoPlay
            playsInline
            muted="muted"
            src={require("../videos/featured_hero_s.mp4")}
          ></video>
        </section>

        <section className="section_container page_archi_content dark">
          <div className="inner_content home_content">
            <article className="row_area_ms">
              <div className="col_1 title">
                <h3>
                  一層一戶
                  <br />
                  獨享廣袤天地
                </h3>
                {/* <p></p> */}
              </div>
              <div className="col_1 context">
                <p>
                  這棟建築，是給了解自己需求，知曉自己不再需要無謂的社交來證明什麼、也不再需要富貴的配件來妝點什麼的人。一層一戶，降低了公設，保留了環景視野，同時搭配最方正而純粹的室內空間。鄰近捷運，也鄰近青山和綠水。生活機能便利，又滿足與自然共舞的願望。什麼樣的生活最好，其實你最知道。
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="section_container freespace page_archi dark">
          <div className="cover_intro">
            <div className="upper">
              <h4>
                優雅家屋
                <br />
                陽台漸層位移
              </h4>
            </div>
            <div className="lower">
              <h5>
                外觀幾何變化趣味
                <br />
                錯動和退縮的韻律
                <br />
                恰似遠山中生動的疊嶂
              </h5>
            </div>
          </div>
          <div className="inner_cover">
            <div
              style={{
                backgroundImage: `url(${require("../images/planeasyfloor/vr-0-1.jpg")})`,
              }}
              className="photo ratio_16_9"
            ></div>
            {/* <div className="slide-container">
              <Fade {...properties}>
                <div className="each-fade">
                  <div className="image-container">
                    <div
                      style={{
                        backgroundImage: `url(${require("../images/archi/archi_b.jpg")})`,
                      }}
                      className="photo ratio_16_9"
                    ></div>
                  </div>
                </div>
              </Fade>
            </div> */}
          </div>
        </section>

        {/* <section
          className="freespace comparisonSection page_archi full_width"
          id="freespace"
        >
          <div className="cover_intro">
            <div className="upper">
              <h4>
                自由平面
                <br />
                全齡彈性空間
              </h4>
            </div>
            <div className="lower">
              <h5>
                方正的室內格局
                <br />
                任君變換 Style
                <br />
                打造專屬的家
              </h5>
            </div>
          </div>
          <div
            className="cover photo photo_a"
            // style={{
            //   backgroundImage: `url(${require("../images/featured_freespace_a.jpg")})`,
            // }}
          >
            <img src={require("../images/featured_freespace_a.jpg")} alt="" />
          </div>
          <div
            className="cover photo photo_b afterImage"
            // style={{
            //   backgroundImage: `url(${require("../images/featured_freespace_b.jpg")})`,
            // }}
          >
            <img src={require("../images/featured_freespace_b.jpg")} alt="" />
          </div>
        </section> */}
        <section className="plantation page_archi" id="plantation">
          <div className="row">
            <div className="col_2 plantation_intro cover_intro">
              <div className="upper">
                <h4>
                  開闊面寬
                  <br />
                  悠遊群山綠水
                </h4>
              </div>
              <div className="mid">
                <h5>
                  寬敞陽台
                  <br />
                  大片落地窗
                  <br />
                  迎來陽光也迎來美景
                </h5>
              </div>
              <div className="lower photo">
                <img src={require("../images/plantation_l.jpg")} alt="" />
              </div>
            </div>
            <div className="col_5 plantation_photo">
              <div className="photo">
                <img
                  src={require("../images/planeasyfloor/vr-5-2s.jpeg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="lighting_video page_archi" id="lighting_video">
          <video
            className="lighting_animation"
            loop
            autoPlay
            playsInline
            muted="muted"
            src={require("../videos/featured_loop_s.mp4")}
          ></video>
        </section>
        <section
          className="lighting_building page_archi"
          id="lighting_building"
        >
          <div className="cover_intro">
            <div className="upper">
              <h4>
                謐光環境
                <br />
                匠心獨具作品
              </h4>
            </div>
            <div className="lower">
              <h5>
                夜間照明也講究品味
                <br />
                用心在細節才有極致成品
              </h5>
            </div>
          </div>
          <div className="cover">
            <div
              className="building lighting_1"
              // style={{
              // backgroundImage: `url(${require("../images/archi/lighting_a.png")})`,
              // }}
            ></div>
            <div
              className="building lighting_2"
              // style={{
              // backgroundImage: `url(${require("../images/archi/lighting_b.png")})`,
              // }}
            ></div>
          </div>
          <div className="lighting_switch">
            <div className="switch_btn sun active">
              <div className="icon icon_sun"></div>
            </div>
            <div className="switch_btn moon">
              <div className="icon icon_moon"></div>
            </div>
          </div>
        </section>
        <section className="construction page_archi" id="construction">
          <div className="cover_intro">
            <div className="upper">
              <h4>
                璞園建築團隊
                <br />
                精工細作
              </h4>
            </div>
            <div className="lower">
              <a
                className="link_btn"
                href="https://www.pauian-universe.com.tw/"
                target="_blank"
              >
                了解璞園建築　↗
              </a>
            </div>
          </div>
          <div
            className="cover photo"
            style={{
              backgroundImage: `url(${require("../images/home_item_d.jpg")})`,
            }}
          ></div>
        </section>

        <SelectBuilding />

        <Helmet
          title="建築特色・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "建築特色・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        ></Helmet>
      </>
    );
  }
}

export default Page;
