import * as TYPE from "../constant/point_type";
import foodIconColor from "./../images/map/icon_color_food.png";
import restaurantIconColor from "./../images/map/icon_color_restaurant.png";
import shoppingIconColor from "./../images/map/icon_color_shopping.png";
import medicalIconColor from "./../images/map/icon_color_medical.png";
import greenIconColor from "./../images/map/icon_color_green.png";
import schoolIconColor from "./../images/map/icon_color_school.png";
import foodIconGray from "./../images/map/icon_gray_food.png";
import restaurantIconGray from "./../images/map/icon_gray_restaurant.png";
import shoppingIconGray from "./../images/map/icon_gray_shopping.png";
import medicalIconGray from "./../images/map/icon_gray_medical.png";
import greenIconGray from "./../images/map/icon_gray_green.png";
import schoolIconGray from "./../images/map/icon_gray_school.png";

const data = {
  [TYPE.FOOD]: {
    name: '飲食',
    iconColor: foodIconColor,
    iconGray: foodIconGray,
    // center: {lat: , lng: },
    // zoom: 15
  },
  [TYPE.RESTAURANT]: {
    name: '餐廳',
    iconColor: restaurantIconColor,
    iconGray: restaurantIconGray,
  },
  [TYPE.SHOPPING]: {
    name: '購物',
    iconColor: shoppingIconColor,
    iconGray: shoppingIconGray,
  },
  [TYPE.MEDICAL]: {
    name: '醫療',
    iconColor: medicalIconColor,
    iconGray: medicalIconGray,
  },
  [TYPE.GREEN]: {
    name: '綠地',
    iconColor: greenIconColor,
    iconGray: greenIconGray,
  },
  [TYPE.SCHOOL]: {
    name: '教育',
    iconColor: schoolIconColor,
    iconGray: schoolIconGray,
  },
}

export default data;