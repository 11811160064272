import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import useWindowSize from "react-use/esm/useWindowSize.js";
import { gsap } from "gsap";
import InnerHeight from "ios-inner-height";

import sty from "./InteractionBgCanvas.module.scss";

import Line from "./canvas/Line.js";

const InteractionBgCanvas = ({
  strokeColor,
  bgColor,
  interactionColorConfig,
  isCleanTheCanvas,
  cleanCanvasPosition,
  handleCloseCleanCanvas,
  strokeWidthScale,
}) => {
  const refCanvas = useRef(null);
  const { width: winWidth, height: winHeight } = useWindowSize();
  const [canvasHeight, setCanvasHeight] = useState(InnerHeight());
  const isInitialMount = useRef(true);
  const refLastMouseTouchPosition = useRef({ x: false, y: false });
  const refNowStrokeColor = useRef(strokeColor);
  const refClickEventPosition = useRef({ pageX: 0, pageY: 0 });

  let isDecaying = false;
  let listOfActiveLines = [];

  useEffect(() => {
    fillBackground();

    Line.canvasEnvSettings.strokeWidth =
      winWidth > 768
        ? winWidth * strokeWidthScale[0]
        : winWidth * strokeWidthScale[1];

    if (isMobile) {
      window.addEventListener("touchmove", onMouseOverTouchMove);
    } else {
      window.addEventListener("mousemove", onMouseOverTouchMove);
    }

    return () => {
      window.removeEventListener("touchmove", onMouseOverTouchMove);
      window.removeEventListener("mousemove", onMouseOverTouchMove);
    };
  }, []);

  useEffect(() => {
    fillBackground();
    setCanvasHeight(InnerHeight());
    Line.canvasEnvSettings.strokeWidth =
      winWidth > winHeight
        ? winWidth * strokeWidthScale[0]
        : winWidth * strokeWidthScale[1];

    return () => {};
  }, [winWidth]);

  // update stroke color
  useEffect(() => {
    refNowStrokeColor.current = strokeColor;
    return () => {};
  }, [strokeColor, interactionColorConfig]);

  useEffect(() => {
    interactionColorConfig &&
      interactionColorConfig.forEach(({ domID, type, color }) => {
        setTimeout(() => {
          try {
            const target = document.getElementById(domID);
            const eventListenerTypes =
              type === "click" ? ["click"] : ["mouseover", "touchstart"];
            eventListenerTypes.forEach((eventType) => {
              target.addEventListener(
                eventType,
                type === "click"
                  ? updateRefClickPosition
                  : changeRefNowStrokeColor.bind(null, color)
              );
            });
          } catch (error) {
            // console.log(error);
          }
        }, 1000);
      });
    return () => {};
  }, [interactionColorConfig]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (isCleanTheCanvas) {
        cleanCanvas(refClickEventPosition.current);
        handleCloseCleanCanvas();
        refClickEventPosition.current.pageX = 0;
        refClickEventPosition.current.pageY = 0;
      }
    }
    return () => {};
  }, [isCleanTheCanvas]);

  function changeRefNowStrokeColor(color) {
    refNowStrokeColor.current = color;
  }

  function updateRefClickPosition(e) {
    refClickEventPosition.current.pageX = e.pageX;
    refClickEventPosition.current.pageY = e.pageY;
  }

  function fillBackground() {
    const ctx = refCanvas.current.getContext("2d");
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, window.innerWidth, InnerHeight());
  }

  function cleanCanvas(clickEvent) {
    isDecaying = false;
    let circle = { radius: 0 };

    const clickPosition = clickEvent &&
      clickEvent.pageX && {
        x: clickEvent.pageX - window.scrollX,
        y: clickEvent.pageY - window.scrollY,
      };

    gsap.to(circle, {
      radius: (winWidth > winHeight ? winWidth : winHeight) * 1.3,
      onUpdate: drawCircle,
    });

    const ctx = refCanvas.current.getContext("2d");
    const { x, y } = clickPosition ||
      cleanCanvasPosition || {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2,
      };

    function drawCircle() {
      ctx.beginPath();
      ctx.arc(x, y, circle.radius, 0, Math.PI * 2); // Mouth (clockwise)
      ctx.fillStyle = bgColor;
      ctx.fill();
    }
  }

  function onMouseOverTouchMove(e) {
    refLastMouseTouchPosition.current.x &&
      listOfActiveLines.push(
        new Line({
          fromXY: refLastMouseTouchPosition.current,
          toXY: {
            x:
              (e.pageX ||
                (e.touches && e.touches[0].pageX) ||
                (e.changedTouches && e.changedTouches[0].pageX)) -
              window.scrollX,
            y:
              (e.pageY ||
                (e.touches && e.touches[0].pageY) ||
                (e.changedTouches && e.changedTouches[0].pageY)) -
              window.scrollY,
          },
          color: refNowStrokeColor.current,
          ctx: refCanvas.current.getContext("2d"),
        })
      );

    refLastMouseTouchPosition.current = {
      x:
        (e.pageX ||
          (e.touches && e.touches[0].pageX) ||
          (e.changedTouches && e.changedTouches[0].pageX)) - window.scrollX,
      y:
        (e.pageY ||
          (e.touches && e.touches[0].pageY) ||
          (e.changedTouches && e.changedTouches[0].pageY)) - window.scrollY,
    };
    if (!isDecaying) {
      isDecaying = true;
      requestAnimationFrame(makeLinesDecay);
    }
  }

  function makeLinesDecay() {
    listOfActiveLines = listOfActiveLines.filter((line) => {
      line.drawLine();
      return line.decayCountdown !== 0;
    });

    // 停止衰變
    if (listOfActiveLines.length === 0 || !isDecaying) {
      isDecaying = false;
    } else {
      requestAnimationFrame(makeLinesDecay);
    }
  }

  return (
    <div className={sty.InteractionBgCanvas}>
      {process.browser && (
        <canvas ref={refCanvas} width={winWidth} height={canvasHeight} />
      )}
    </div>
  );
};

InteractionBgCanvas.defaultProps = {
  strokeWidthScale: [0.15, 0.2],
};

InteractionBgCanvas.propTypes = {
  isCleanTheCanvas: PropTypes.bool,
  cleanCanvasPosition: PropTypes.object,
  handleCloseCleanCanvas: PropTypes.func,
  strokeColor: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  interactionColorConfig: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["hover", "click"]),
      domID: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
  strokeWidthScale: PropTypes.array,
};

export default InteractionBgCanvas;
