import floor14 from "./../images/floor_new/f14.jpeg";
import floor13 from "./../images/floor_new/f13.jpeg";
import floor12 from "./../images/floor_new/f12.jpeg";
import floor11_1 from "./../images/floor_new/f11_1.jpeg";
import floor11_2 from "./../images/floor_new/f11_2.jpeg";
import floor11_3 from "./../images/floor_new/f11_3.jpeg";
import floor11_4 from "./../images/floor_new/f11_4.jpeg";
// import floor11_4_1 from "./../images/floor_new/f11_photo-1.jpeg";
// import floor11_4_2 from "./../images/floor_new/f11_photo-2.jpeg";
// import floor11_4_3 from "./../images/floor_new/f11_photo-3.jpeg";
// import floor11_4_4 from "./../images/floor_new/f11_photo-4.jpeg";
// import floor11_4_5 from "./../images/floor_new/f11_photo-5.jpeg";

import floor11_4_1 from "./../images/planeasyfloor/vr-1-1.jpeg";
import floor11_4_2 from "./../images/planeasyfloor/vr-2-1.jpeg";
import floor11_4_3 from "./../images/planeasyfloor/vr-3-1.jpeg";
import floor11_4_4 from "./../images/planeasyfloor/vr-4-1.jpeg";
import floor11_4_5 from "./../images/planeasyfloor/vr-5-1.jpeg";

import floor10 from "./../images/floor_new/f10.jpeg";
import floor9 from "./../images/floor_new/f9.jpeg";
import floor8 from "./../images/floor_new/f8.jpeg";
import floor8_1 from "./../images/floor_new/f8-1.jpeg";
import floor8_2 from "./../images/floor_new/f8-2.jpeg";
import floor7 from "./../images/floor_new/f7.jpeg";
import floor6 from "./../images/floor_new/f6.jpeg";
import floor3 from "./../images/floor_new/f3.jpeg";
import floor2 from "./../images/floor_new/f2.jpeg";
import floor1 from "./../images/floor_new/f1.jpeg";
// import floor0 from "./../images/floor_new/f0.jpeg";
import b1 from "./../images/floor_new/b1.jpg";
import b2 from "./../images/floor_new/b2.jpg";
import b3 from "./../images/floor_new/b3.jpg";

const data = {
  B3: {
    displayedShortName: "B3",
    plans: [b3],
    pingOfTotal: null,
    pingOfBalcony: null,
    floorHeight: -9.3,
  },
  B2: {
    displayedShortName: "B2",
    plans: [b2],
    floorHeight: -6.3,
  },
  B1: {
    displayedShortName: "B1",
    plans: [b1],
    floorHeight: -3.3,
  },
  F1: {
    displayedShortName: "1",
    vrVideoLink: "",
    view360ImgUrl: "",
    plans: [floor1],
    pingOfTotal: 45.48,
    pingOfBalcony: 5.27,
    publicRatio: 30.64,
    floorHeight: 4.5,
  },
  F2: {
    displayedShortName: "2",
    plans: [floor2],
    pingOfTotal: 50.08,
    pingOfBalcony: 2.61,
    publicRatio: 34.75,
    floorHeight: 7.9,
  },
  F3: {
    displayedShortName: "3",
    plans: [floor3],
    pingOfTotal: 80.13,
    pingOfBalcony: 4.4,
    publicRatio: 33.66,
    floorHeight: 11.3,
  },
  F4: {
    displayedShortName: "4",
    plans: [floor3],
    pingOfTotal: 80.13,
    pingOfBalcony: 4.4,
    publicRatio: 33.66,
    floorHeight: 14.7,
    // fullView: "../view_50m/?fl=F4",
  },
  F5: {
    displayedShortName: "5",
    plans: [floor3],
    pingOfTotal: 80.13,
    pingOfBalcony: 4.4,
    publicRatio: 33.66,
    floorHeight: 18.1,
    // fullView: "../view_50m/?fl=F5",
  },
  F6: {
    displayedShortName: "6",
    plans: [floor6],
    pingOfTotal: 66.01,
    pingOfBalcony: 6.56,
    publicRatio: 32.82,
    floorHeight: 21.5,
    fullView: "../view_24m/?fl=F6",
  },
  F7: {
    displayedShortName: "7",
    plans: [floor7],
    pingOfTotal: 53.98,
    pingOfBalcony: 2.98,
    publicRatio: 33.6,
    floorHeight: 24.9,
    fullView: "../view_24m/?fl=F7",
  },
  F8: {
    displayedShortName: "8",
    plansTitle: ["Plan A", "Plan B", "虛擬樣品屋"],
    // plans: [floor8, floor8],
    plans: [
      floor8_1,
      floor8_2,
      [floor11_4_1, floor11_4_2, floor11_4_3, floor11_4_4, floor11_4_5],
    ],
    pingOfTotal: 54.88,
    pingOfBalcony: 3.65,
    publicRatio: 33.52,
    floorHeight: 28.3,
    fullView: "../view_24m/?fl=F8",
  },
  F9: {
    displayedShortName: "9",
    plans: [floor9],
    pingOfTotal: 55.87,
    pingOfBalcony: 3.37,
    publicRatio: 33.43,
    floorHeight: 31.7,
    fullView: "../view_37m/?fl=F9",
  },
  F10: {
    displayedShortName: "10",
    plans: [floor10],
    pingOfTotal: 55.98,
    pingOfBalcony: 3.44,
    publicRatio: 33.44,
    floorHeight: 35.1,
    fullView: "../view_37m/?fl=F10",
  },
  F11: {
    displayedShortName: "11",
    plansTitle: ["Plan A", "Plan B", "Plan C"],
    plans: [floor11_1, floor11_2, floor11_3],
    pingOfTotal: 55.85,
    pingOfBalcony: 3.35,
    publicRatio: 33.44,
    floorHeight: 38.5,
    // vrLink: "123",
    fullView: "../view_37m/?fl=F11",
  },
  F12: {
    displayedShortName: "12",
    plans: [floor12],
    pingOfTotal: 55.98,
    pingOfBalcony: 3.44,
    publicRatio: 33.44,
    floorHeight: 41.9,
    fullView: "../view_50m/?fl=F12",
  },
  F13: {
    displayedShortName: "13",
    plans: [floor13],
    pingOfTotal: 55.49,
    pingOfBalcony: 3.35,
    publicRatio: 33.48,
    floorHeight: 45.3,
    fullView: "../view_50m/?fl=F13",
  },
  F14: {
    displayedShortName: "14",
    plans: [floor14],
    pingOfTotal: 55.08,
    pingOfBalcony: 2.56,
    publicRatio: 33.52,
    floorHeight: 48.7,
    fullView: "../view_50m/?fl=F14",
  },
};

export default data;
