import Color from "color";

export default class Line {
  static canvasEnvSettings = {
    strokeWidth: 10, //15,20
  };

  constructor({ fromXY, toXY, color = "red", ctx }) {
    this.ctx = ctx;
    this.fromXY = fromXY;
    this.toXY = toXY;
    this.initialColor = color;
    this.decayCountdown = 25;
    this.drawLine();
  }

  checkIsDecayComplete = () => this.decayCountdown === 0;

  getDecayStrokeColor = () => {};

  drawLine = () => {
    this.ctx.beginPath();
    // TODO: 色調整
    this.ctx.strokeStyle = Color(this.initialColor).lighten(
      this.decayCountdown / (25 * 10)
    );
    // .saturate(this.decayCountdown / (25 * 2));
    // console.log(this.ctx.strokeStyle);
    this.ctx.lineWidth = Line.canvasEnvSettings.strokeWidth;
    this.ctx.lineJoin = "round";
    this.ctx.moveTo(this.fromXY.x, this.fromXY.y);
    this.ctx.lineTo(this.toXY.x, this.toXY.y);
    this.ctx.closePath();
    this.ctx.stroke();

    this.decayCountdown = this.decayCountdown - 1;

    // this.ctx.beginPath();
    // this.ctx.fillStyle = "#000000";
    // this.ctx.arc(
    //   this.position.x,
    //   this.position.y,
    //   Point.canvasEnvSettings.strokeWidth,
    //   0,
    //   2 * Math.PI
    // );
    // this.ctx.fill();
  };
}
