import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import loadable from "@loadable/component";

// var og_img = 'https://online.home-to-all.com' + require('../images/422socialimage.jpg');
// var ios_icon = require('../images/ios_icon.png');
// var ms_icon = require('../images/ms_icon.png');

const Index = (props) => {
  return <>{/* <Page /> */}</>;
};

// Index.propTypes = {}

export default Index;
