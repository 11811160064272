import React, { useState, useCallback, useEffect } from "react";
import loadable from "@loadable/component";

const Home = loadable(() =>
  import(/* webpackPreload: true */ "../../components/home.js")
);
const Archi = loadable(() =>
  import(/* webpackPreload: true */ "../../components/archi.js")
);
const PlanEasyFloor = loadable(() =>
  import(/* webpackPreload: true */ "../../components/planeasyfloor.js")
);
const Page = loadable(() =>
  import(/* webpackPreload: true */ "../../components/page.js")
);
const Floor = loadable(() => import(/* webpackPreload: true */ "../Floor"));
const LocationMap = loadable(() =>
  import(/* webpackPreload: true */ "../LocationMap")
);
const Booking = loadable(() => import(/* webpackPreload: true */ "../Booking"));
const View = loadable(() => import(/* webpackPreload: true */ "../View"));
const View_24m = loadable(() =>
  import(/* webpackPreload: true */ "../View_24m")
);
const View_37m = loadable(() =>
  import(/* webpackPreload: true */ "../View_37m")
);
const View_50m = loadable(() =>
  import(/* webpackPreload: true */ "../View_50m")
);
const Select = loadable(() =>
  import(/* webpackPreload: true */ "../SelectBuilding")
);
const VrVideo = loadable(() => import(/* webpackPreload: true */ "../VrVideo"));

export default [
  { path: "/", Component: Home },
  { path: "/floor/:floorKey", Component: Floor },
  { path: "/location", Component: LocationMap },
  { path: "/booking", Component: Booking },
  { path: "/archi", Component: Archi },
  { path: "/planeasyfloor", Component: PlanEasyFloor },
  { path: "/view", Component: View },
  { path: "/view_24m", Component: View_24m },
  { path: "/view_37m", Component: View_37m },
  { path: "/view_50m", Component: View_50m },
  { path: "/select", Component: Select },
  { path: "/vrexperience", Component: VrVideo },
  // { path: "/exhibition", Component: ExhibitionTable },
];
