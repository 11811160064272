import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import sty from "./IndexSPA.module.scss";

import routerConfig from "./route.conf.js";

import TransitionMatchHOC from "../../components/TransitionMatchHOC";
import ContextProvider from "../../hooks/useContextSPATransitionLock.js";
import InteractionBgCanvas from "../../components/InteractionBgCanvas/index.js";
import Layout from "../../components/layout.js";
import VideoIsPlayedContext from './../context'

const IndexSPA = ({ children }) => {
  const [isMount, setIsMount] = useState(false);
  const [isReadyToPreFetch, setIsReadyToPreFetch] = useState(false);
  const [indexVideoIsPlayed, setIndexVideoIsPlayed] = useState(false);
  const VideoIsPlayedContextProvider = VideoIsPlayedContext.Provider
  const videoIsPlayedContext = {
    indexVideoIsPlayed,
    setIndexVideoIsPlayed
  }

  useEffect(() => {
    setIsMount(true);
    return () => {
      // setIsMount(false);
    };
  }, []);

  return (
    <>
      <VideoIsPlayedContextProvider value={videoIsPlayedContext}>
        <ContextProvider>
            <Layout>
              {routerConfig.map(({ path, Component }, index) => (
                <TransitionMatchHOC key={index + path} path={path}>
                  <Component />
                </TransitionMatchHOC>
              ))}
              {/* <InteractionBgCanvas /> */}
            </Layout>
        </ContextProvider>
      </VideoIsPlayedContextProvider>
    </>
  );
};

IndexSPA.propTypes = {};

export default IndexSPA;
