import React, {
  Suspense,
  lazy,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Router, Link } from "@reach/router";
import Helmet from "react-helmet";
import {
  GoogleMap,
  Marker,
  Polyline,
  LoadScript,
  useGoogleMap,
} from "@react-google-maps/api";

import pointTypes from "./../../data/map_point_type";
import points from "./../../data/map_point";
import googleMapStyles from "./../../data/google_map_styles";
import * as POINT_TYPE from "../../constant/point_type";
// import centerMarkerIcon from "../../images/map/center_marker_icon.png";

import centerMarkerIcon from "../../images/map/center_marker_icon_2.png";

import routesTypeInfo from "../../data/map_route_type_info";
import routes from "./../../data/map_route_v2";
import * as ROUTE from "../../constant/route";

import InnerHeight from "ios-inner-height";

import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";

const defaultPointType = POINT_TYPE.FOOD;
let defaultPointTypes = {};
Object.keys(pointTypes).map(
  (pointType) => (defaultPointTypes[pointType] = pointType === defaultPointType)
);
const maxZoom = 18;
const minZoom = 14;

const Page = () => {
  const [mounted, setMounted] = useState(false);
  // const [mapLoaded, setMapLoaded] = useState(false);
  const [center, setCenter] = useState({ lat: 25.098444, lng: 121.531038 });
  const [activePointTypes, setActivePointTypes] = useState(defaultPointTypes);
  const [activePoints, setActivePoints] = useState(points);
  const [activeRouteType, setActiveRouteType] = useState(null);
  const [routePath, setRoutePath] = useState({});
  const [routePathIndex, setRoutePathIndex] = useState({});

  const [map, setMap] = useState(null);
  const tooltipRef = useRef(null);
  const [routeIconNag, setRouteIconNag] = useState({});

  useEffect(() => {
    setMounted(true);
  }, []);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handlePointTypeClick = (e, triggeredType) => {
    e.preventDefault();
    // setActivePointTypes((prevState) => ({
    //   ...prevState,
    //   [triggeredType]: !prevState[triggeredType],
    // }));
    setActivePointTypes((prevState) => {
      let newState = { ...prevState };
      Object.keys(prevState).map(
        (key) => (newState[key] = key === triggeredType)
      );
      return newState;
    });
    layoutPoints();
  };

  const onBoundsChanged = () => {
    layoutPoints();
    if (isMobile) tooltipRef.current.classList = "";
  };

  const layoutPoints = () => {
    let newPoints = [];
    const currentBounds = map.getBounds();
    let NE = currentBounds.getNorthEast();
    let SW = currentBounds.getSouthWest();
    let maxLat = NE.lat(),
      minLat = SW.lat(),
      maxLng = NE.lng(),
      minLng = SW.lng();

    for (let i = 0; i < points.length; i++) {
      let lat = points[i].lat,
        lng = points[i].lng;
      let dLat = Math.abs(maxLat - minLat) / 25,
        dLng = Math.abs(maxLng - minLng) / 60;

      if (lat < minLat + dLat) lat = minLat + dLat / 3;
      else if (lat > maxLat - dLat) lat = maxLat - dLat;

      if (lng < minLng + dLng) lng = minLng + dLng;
      else if (lng > maxLng - dLng) lng = maxLng - dLng;

      let isGray = lat !== points[i].lat || lng !== points[i].lng;

      newPoints.push({
        lat,
        lng,
        isGray,
        type: points[i].type,
        tooltip: points[i].name,
      });
    }

    setActivePoints(newPoints);
  };

  const handleRouteClick = (e, triggeredRouteType) => {
    e.preventDefault();
    // map.panTo({ lat: 25.098854, lng: 121.529103 });
    // map.setZoom(15);
    let currentRouteType =
      triggeredRouteType === activeRouteType ? null : triggeredRouteType;
    let routesPath = {},
      routesPathIndex = {};
    if (currentRouteType) {
      Object.keys(routesTypeInfo[currentRouteType].routes).map((key) => {
        routesPath[key] = [];
        routesPathIndex[key] = 0;
      });
    }
    setRoutePath(routesPath);
    setRoutePathIndex(routesPathIndex);
    setActiveRouteType(currentRouteType);
  };

  useEffect(() => {
    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.add("inner");

    // var map_point_switch = document.querySelector(".map_point_switch");
    // var map_point_type_wrap = document.querySelector(".map_point_type_wrap");
    // map_point_switch.addEventListener("click", function() {
    //   map_point_type_wrap.classList.toggle("active");
    //   map_point_switch.classList.toggle("active");
    // });

    if (activeRouteType) {
      // console.log('activeRouteType: ', activeRouteType)
      // let routeIntervals = {};
      // Object.keys(routesTypeInfo[activeRouteType].routes).map((key, i) => {
      //   routeIntervals[key] = setInterval(() => {
      //     // console.log(key)
      //     setRoutePathIndex((prevIndexs) => {
      //       console.log(prevIndexs)
      //       const prevIndex = prevIndexs[key];
      //       console.log(key, prevIndex)
      //       setRoutePath((prevPaths) => {
      //         console.log(prevPaths)
      //         return ({
      //           ...prevPaths,
      //           [key]: [
      //             ...prevPaths[key],
      //             routes[key][prevIndex],
      //           ]
      //         })
      //       });

      //       if (prevIndex >= routes[key].length - 1) {
      //         clearInterval(routeIntervals[key]);
      //         return ({
      //           ...prevIndexs,
      //           [key]: prevIndex
      //         });
      //       } else {
      //         let newIndex = prevIndex + 1;
      //         // setRouteIconNag(prevIcons => )
      //         //   routes[key][newIndex].lng -
      //         //     routes[key][newIndex].lng <
      //         //     0
      //         // );
      //         return ({
      //           ...prevIndexs,
      //           [key]: newIndex
      //         });
      //       }
      //     });
      //   }, 80);
      // })

      ///////////////////////////////////////////////////////////

      // let routeInterval = setInterval(() => {
      //   let newRoutePath = JSON.parse(JSON.stringify(routePath)), newRoutePathIndex = JSON.parse(JSON.stringify(routePathIndex));
      //   Object.keys(routesTypeInfo[activeRouteType].routes).map((key, i) => {

      //     newRoutePath[key] = [...newRoutePath[key], routes[key][newRoutePathIndex[key]]];

      //     // console.log(routePathIndex);
      //     // const prevIndex = routePathIndex[key];

      //     // setRoutePath((prevPaths) => {
      //     //   // console.log(prevPaths)
      //     //   return ({
      //     //     ...prevPaths,
      //     //     [key]: [
      //     //       ...prevPaths[key],
      //     //       routes[key][prevIndex],
      //     //     ]
      //     //   })
      //     // });

      //     // console.log(prevIndex, routes[key].length)
      //     if (newRoutePathIndex[key] >= routes[key].length - 1) {
      //       clearInterval(routeInterval);
      //       // setRoutePathIndex((prevIndexs) => ({
      //       //   ...prevIndexs,
      //       //   [key]: prevIndex
      //       // }));
      //     } else {
      //       console.log('hello?')
      //       // setRoutePathIndex((prevIndexs) => ({
      //       //   ...prevIndexs,
      //       //   [key]: prevIndex + 1
      //       // }));
      //       newRoutePathIndex[key] = newRoutePathIndex[key] + 1;
      //     }

      //   });
      //   console.log(routePath, newRoutePath)
      //   console.log(routePathIndex, newRoutePathIndex)
      //   // setTimeout(() => {
      //     setRoutePath(newRoutePath);
      //     setRoutePathIndex(newRoutePathIndex);
      //   // }, 40)

      // }, 80);

      ///////////////////////////////////////////////////////////

      let routeInterval = setInterval(() => {
        setRoutePath((prevPaths) => {
          let newRoutePath = {};
          // newRoutePathIndex = JSON.parse(JSON.stringify(routePathIndex));
          Object.keys(routesTypeInfo[activeRouteType].routes).map((key, i) => {
            newRoutePath[key] = [
              ...prevPaths[key],
              routes[key][prevPaths[key].length],
            ];
          });
          // console.log(newRoutePath)
          return newRoutePath;
        });

        setRoutePathIndex((prevPathIndexs) => {
          let newRoutePathIndex = {};
          // console.log(routes)
          let count = 0;
          Object.keys(routesTypeInfo[activeRouteType].routes).map((key, i) => {
            if (prevPathIndexs[key] >= routes[key].length - 1) count += 1;

            if (prevPathIndexs[key] >= routes[key].length - 1) {
              //   clearInterval(routeInterval);
              // console.log('stop')
              newRoutePathIndex[key] = prevPathIndexs[key];
            } else {
              newRoutePathIndex[key] = prevPathIndexs[key] + 1;
            }
          });

          if (
            Object.keys(routesTypeInfo[activeRouteType].routes).length === count
          ) {
            // console.log('end')
            clearInterval(routeInterval);
          }

          // console.log(newRoutePathIndex)
          return newRoutePathIndex;
        });
      }, 40);

      return () => {
        // Object.keys(routeIntervals).map((key) => clearInterval(routeIntervals[key]));
        clearInterval(routeInterval);
      };
    }
  }, [activeRouteType]);

  return (
    <div className="location_map_container">
      <>
        <Helmet
          title="生活地圖・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "生活地圖・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        ></Helmet>
      </>
      {typeof window !== "undefined" && mounted && (
        <LoadScript googleMapsApiKey="AIzaSyDE0oxTQYqN2VuvxmnnVvIpMWH65mX1kHs">
          <GoogleMap
            options={{
              fullscreenControl: false,
              mapTypeControl: false,
              streetViewControl: false,
              zoomControl: false,
              scaleControl: false,
              styles: googleMapStyles,
              scrollwheel: isMobile ? true : false,
              maxZoom,
              minZoom,
            }}
            mapContainerStyle={{ width: "100vw", height: window.innerHeight }}
            center={center}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
          >
            <Marker
              position={{ lat: 25.098444, lng: 121.531038 }}
              animation={4}
              icon={{
                url: centerMarkerIcon,
                scaledSize: { width: 30, height: 60 },
              }}
            />
            {activePoints.map((point, index) => (
              <Marker
                key={index}
                position={{ lat: point.lat, lng: point.lng }}
                icon={{
                  url: point.isGray
                    ? pointTypes[point.type].iconGray
                    : pointTypes[point.type].iconColor,
                  scaledSize: { width: 40, height: 40 },
                }}
                opacity={activePointTypes[point.type] ? 1 : 0}
                zIndex={activePointTypes[point.type] ? 100 : -1}
                onClick={(e) => {
                  if (isMobile && activePointTypes[point.type]) {
                    tooltipRef.current.classList = "";
                    let pixel = e.pixel;
                    tooltipRef.current.innerHTML = point.tooltip;
                    tooltipRef.current.style.left = `calc(50% + ${pixel.x}px)`;
                    tooltipRef.current.style.top = `calc(50% + ${pixel.y}px)`;
                    tooltipRef.current.classList = "active";
                  }
                }}
                onMouseOver={(e) => {
                  if (activePointTypes[point.type]) {
                    tooltipRef.current.classList = "";
                    let pixel = e.pixel;
                    tooltipRef.current.innerHTML = point.tooltip;
                    tooltipRef.current.style.left = `calc(50% + ${pixel.x}px)`;
                    tooltipRef.current.style.top = `calc(50% + ${pixel.y}px)`;
                    tooltipRef.current.classList = "active";
                  }
                }}
                onMouseOut={(e) => {
                  if (activePointTypes[point.type]) {
                    tooltipRef.current.classList = "";
                  }
                }}
              />
            ))}
            {activeRouteType
              ? Object.keys(routePath).map((key, i) => (
                  <>
                    <Polyline
                      path={routePath[key]}
                      options={{
                        geodesic: true,
                        strokeColor: "#008808",
                        strokeOpacity: 1,
                        strokeWeight: 3,
                        editable: false,
                      }}
                    />
                    <Marker
                      position={{
                        lat: routes[key][routePathIndex[key]].lat,
                        lng: routes[key][routePathIndex[key]].lng,
                      }}
                      icon={{
                        url: !routeIconNag
                          ? routesTypeInfo[activeRouteType].iconColor
                          : routesTypeInfo[activeRouteType].iconColorNag,
                        // url: routesTypeInfo[activeRouteType].iconColor,
                        scaledSize: { width: 40, height: 40 },
                      }}
                    />
                    <Polyline
                      path={routePath}
                      options={{
                        geodesic: true,
                        strokeColor: "#008808",
                        strokeOpacity: 0.5,
                        strokeWeight: 5,
                        editable: false,
                      }}
                    />
                    <Marker
                      position={{
                        lat: routes[key][routePathIndex[key]].lat,
                        lng: routes[key][routePathIndex[key]].lng,
                      }}
                      icon={{
                        // url: !routeIconNag
                        //   ? routesTypeInfo[activeRouteType].iconColor
                        //   : routesTypeInfo[activeRouteType].iconColorNag,
                        url: routesTypeInfo[activeRouteType].iconColor,
                        scaledSize: { width: 40, height: 40 },
                      }}
                    />
                  </>
                ))
              : ""}
            <div id="marker-tooltip" ref={tooltipRef} />
          </GoogleMap>
        </LoadScript>
      )}
      {/* <div className="map_point_switch">
        生活周邊 <i className="fa fa-angle-down"></i>
      </div> */}
      <div className="map_point_type_wrap">
        {/* <h6 className="list_title">類別</h6> */}
        <ul className="type_list">
          {Object.keys(pointTypes).map((pointType, index) => (
            <li
              key={index}
              className="list_item"
              onClick={(e) => handlePointTypeClick(e, pointType)}
            >
              <div
                className={`icon ${
                  activePointTypes[pointType] ? "active" : ""
                }`}
              >
                <i className={pointType}></i>
              </div>
              <div className="icon_label">{pointTypes[pointType].name}</div>
            </li>
          ))}
        </ul>
      </div>

      <div className="map_route_wrap">
        {/* <h6 className="list_title">生活路線</h6> */}
        <ul className="route_list">
          {Object.keys(routesTypeInfo).map((route, index) => (
            <li
              key={index}
              className="list_item"
              onClick={(e) => handleRouteClick(e, route)}
            >
              <div
                className={`icon ${activeRouteType === route ? "active" : ""}`}
              >
                <i className={route}></i>
              </div>
              <div className="icon_label">{routesTypeInfo[route].label}</div>
              {/* <div className="route_title">{routesTypeInfo[route].name}</div> */}
            </li>
          ))}
        </ul>
      </div>

      <div className="map_control">
        <div
          className="zoom_in"
          onClick={(e) => {
            e.preventDefault();
            const currentZoom = map.getZoom();
            if (currentZoom <= maxZoom) map.setZoom(currentZoom + 1);
          }}
        >
          <i className="icon">+</i>
        </div>
        <div
          className="zoom_out"
          onClick={(e) => {
            e.preventDefault();
            const currentZoom = map.getZoom();
            if (currentZoom >= minZoom) map.setZoom(currentZoom - 1);
          }}
        >
          <i className="icon">-</i>
        </div>
      </div>
    </div>
  );
};

export default Page;
