/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
// import IosInnerHeight from "ios-inner-height";

import Link from "../components/LinkWithTransitionLock";
import Cursor from "../components/Cursor";
import Header from "../components/Header";

// const addScript = (url) => {
//   const script = document.createElement("script");
//   script.src = url;
//   script.async = true;
//   document.body.appendChild(script);
// };

// var og_img = 'https://online.home-to-all.com' + require('../images/422socialimage.jpg');
// var ios_icon = require('../images/ios_icon.png');
// var ms_icon = require('../images/ms_icon.png');

// let scrollbar_options = {
//   damping: 0.1,
//   thumbMinSize: 25,
//   renderByPixel: true,
//   alwaysShowTracks: false,
//   continuousScrolling: true
// };

// Scrollbar.init(document.querySelector('.container'), scrollbar_options);

// new LocomotiveScroll({
//   el: document.querySelector('#___gatsby'),
//   smooth: true
// });

// const Wrap_InnerHeight = {
//   width: window.innerWidth + 'px',
//   height: window.innerHeight + 'px',
//   overflowX: 'hidden'
// };

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  // window.onload = () => {
  //   addScript(
  //     "//typesquare.com/3/tsst/script/zh_tw/typesquare.js?60a781ebc9b44558b3fe144aac1e02e5"
  //   );
  // };
  return (
    <>
      <Header />

      <main className="main_container">{children}</main>

      {/* <div className="loading_cover loading"></div> */}

      {/* <Cursor /> */}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
