import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { Router, Link } from "@reach/router";
import * as d3 from "d3";
// import { event as currentEvent } from "d3";

import InnerHeight from "ios-inner-height";
import { isMobile, isSafari } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";

import axios from "axios";
import DatePicker from "react-datepicker";
import {
  format,
  addMonths,
  addDays,
  setHours,
  setMinutes,
  isAfter,
  toDate,
} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

const COLORS = {
  a: "a",
  b: "b",
  c: "c",
  d: "d",
  e: "e",
  f: "f",
};

const options = {
  floor: {
    "0": "第1樓",
    "1": "第2樓",
    "2": "第3樓",
    "3": "第4樓",
    "4": "第5樓",
  },
  bookable: {
    "0": "平日上午：10:00-12:00",
    "1": "平日下午：14:00-16:00",
    "2": "平日下午：16:00-18:00",
  },
  view: {
    null: { label: "無", intro: "" },
    // "0": {
    //   label: "海景",
    //   intro: "白日依山盡，淡水入海流，欲窮千里目，先買一層樓。",
    // },
    "0": {
      label: "山景",
      intro: "陽明山日出，劍南山夜景。夙夜匪懈，知山陪你。",
    },
    "1": { label: "河景", intro: "我家旁邊雙溪河，可愛小泥鰍。" },
    "2": { label: "市景", intro: "大隱於市，就逛士林夜市。" },
  },
  num: {
    null: { label: "無", intro: "" },
    "0": { label: "1人", intro: "眾樂樂不如獨樂樂嘛。" },
    "1": { label: "2人", intro: "給愛多一點空間：兩人三房，幸福滿堂。" },
    "2": {
      label: "3-4人",
      intro: "我們有最標準的三房格局，你一定也在找這個吧？",
    },
    "3": {
      label: "5-6人",
      intro: "孫子的遊戲室、兒子的次臥室、爺爺的主臥室，三房格局一次備齊。",
    },
    // "4": { label: "7人以上", intro: "一次兩戶打通，一家老小無憂。" },
  },
  activity: {
    null: { label: "無", intro: "" },
    "0": { label: "慢跑", intro: "雙溪河慢跑到關渡，馬拉松夢幻瘦小腹。" },
    "1": {
      label: "健行",
      intro: "你的一小步，就是你的一大步：歡迎光臨芝山岩古蹟和生態奇景。",
    },
    "2": {
      label: "騎腳踏車",
      intro: "道可道，非常道，領悟就靠騎一次雙溪水岸自行車道。",
    },
    "3": {
      label: "旅遊",
      intro: "每日都是一場華麗冒險，前提是家離捷運公車快速道路都不遠。",
    },
    "4": {
      label: "攝影",
      intro: "成功是清晨醒來，對自己的鏡頭充滿期待—開窗即見山景四季。",
    },
    "5": {
      label: "逛展覽",
      intro: "天下有一人知己，可以不恨。鄰近有一棟故宮，可以無憾。",
    },
    "6": {
      label: "賞鳥",
      intro: "不管今天你過得有多鳥，芝山岩都有更多畫眉和五色鳥與你同在。",
    },
    "7": {
      label: "發呆",
      intro: "不想追小孩，搞得滿身汗，科教館用愛發電給你冷氣滿滿。",
    },
    "8": { label: "逛百貨公司", intro: "天母SOGO讓貴婦追憶似水年華。" },
    "9": { label: "逛菜市場", intro: "家庭煮夫是你？莫忘來榮華街市場巡禮。" },
    "10": {
      label: "烹飪",
      intro: "汝的煩惱不是手藝不好，而是少了一座中島—welcom to開放式廚房。",
    },
    "11": {
      label: "游泳",
      intro: "跳進福林國小的溫水泳池，想起好久不見的優游人生。",
    },
  },
  style: {
    null: { label: "無", intro: "" },
    "0": {
      label: "古典",
      intro: "古典華麗雖好，環山河景更佳，客官不多考慮一下？",
    },
    "1": { label: "現代", intro: "簡練造型和結構工藝，是我們給你的承諾。" },
    "2": {
      label: "日式",
      intro: "本棟六樓特殊挑高日式空間，來過的都說不滿足於一期一會。",
    },
    "3": {
      label: "北歐",
      intro: "這裡只是沒有雪景，但簡約實用休閒感一項不缺。",
    },
    "4": {
      label: "美式鄉村",
      intro: "一樓灌木叢＋碎花桌布＋原木家具，你也能輕鬆打造鄉村風。",
    },
  },
  price: {
    null: { label: "無", intro: "" },
    // "0": { label: "70-80萬", intro: "我們會再努力，提供你適合的選擇。" },
    // "0": {
    //   label: "100-105萬",
    //   intro: "璞園築觀第三期（永吉路案）快開跑了，再等一下喲。",
    // },
    // "1": {
    //   label: "106-110萬",
    //   intro: "認識你真好！讓我們帶你逛逛知山的2、3樓吧。",
    // },
    // "2": {
    //   label: "111-115萬",
    //   intro: "人生就該把時間花在對的選擇上—請看9樓平面圖。",
    // },
    "0": {
      label: "116-120萬",
      intro: "認識你真好！讓我們也帶你認識璞園吧！",
    },
    "1": {
      label: "121-125萬",
      intro: "人生就該把時間花在對的選擇上—請看一層一戶的平面圖。",
    },
    "2": {
      label: "126-130萬",
      intro: "你知我知天知，本棟的平面自由空間和寬闊美景，就是為你存在。",
    },
    "3": {
      label: "130萬元以上",
      intro: "尊貴的客戶，請留下您的聯絡資料，我們會儘速為您服務！",
    },
  },
  // speed_dating: { null: '無', '0': '20%', '1': '45%', '2': '65%', '3': '95%' }
};

const SECTION = {
  INTRO: "intro",
  QUESTION_A: "view",
  QUESTION_B: "num",
  QUESTION_C: "activity",
  QUESTION_D: "style",
  QUESTION_E: "price",
  // QUESTION_F: 'speed_dating',
  BOOKING_FORM: "booking_form",
};

const Page = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [unbookable, setUnbookable] = useState(false);
  const [checkForm, setCheckForm] = useState(false);
  const [successed, setSuccessed] = useState(false);
  const [info, setInfo] = useState({
    view: null,
    num: null,
    date: addDays(new Date(), 1),
    time: "00:00",
    floor: "3",
    bookable: "0",
    activity: null,
    style: null,
    price: null,
    // speed_dating: null,
    name: "",
    email: "",
    phone: "",
    agreement: false,
  });
  const [errorMessage, setErrorMessage] = useState([]);
  const formattedDate = format(info.date, "yyyy-MM-dd");
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const [bookings, setBookings] = useState({});
  const [rules, setRules] = useState({});
  const [activeSection, setActiveSection] = useState(SECTION.INTRO);
  const [activeIntroIndex, setActiveIntroIndex] = useState(null);
  // console.log(options[activeSection] ? options[activeSection][`${activeIntroIndex}`] : '', activeSection, `${activeIntroIndex}`)
  const orderableNumOfDay = 60;
  const minDate = addDays(new Date(), 1);
  const maxDate = addDays(new Date(), orderableNumOfDay + 1);
  // console.log(info)

  useEffect(() => {
    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.add("inner");
    document.querySelector("body").classList.remove("overflow");

    var booking_form = document.querySelector(".booking_form");
    if (window.innerWidth < 959) {
      booking_form.style.height = window.innerHeight + "px";
    } else {
      booking_form.style.height = window.innerHeight * 0.8 + "px";
    }

    document
      .querySelector(".last_step_btn")
      .addEventListener("click", function() {
        booking_form.scrollTo({
          top: booking_form.scrollHeight * 0.45,
          behavior: "smooth",
        });
      });

    let rules = { weekend: {}, weekday: {} };
    let intervals = [];
    axios({
      method: "GET",
      // url: 'https://pyct.based.design/wp-json/salon/api/v1/availability/booking/services',
      url: "https://pyct.based.design/wp-json/salon/api/v1/services",
      data: {
        // date: '2020-12-21',
        // time: '15:00',
        // check_services: 8
        // services: [{
        //   service_id: 8,
        //   // assistant_id: 6
        //   // service_name: 'weekend'
        // }]
        type: "all",
      },
    })
      .then((response) => {
        let rawItems = response.data.items;
        for (let i = 0; i < rawItems.length; i++) {
          if (rawItems[i].name == "weekend") {
            rules.weekend["id"] = rawItems[i].id;
            rules.weekend["unit"] = rawItems[i].unit;
          }
          if (rawItems[i].name == "weekday") {
            rules.weekday["id"] = rawItems[i].id;
            rules.weekday["unit"] = rawItems[i].unit;
          }
        }

        setRules(rules);
        // console.log(rules)
        return axios({
          method: "GET",
          url: "https://pyct.based.design/wp-json/salon/api/v1/app/settings",
        });
      })
      .then((response) => {
        let rawData = response.data.settings.availabilities;
        for (let i = 0; i < Object.keys(rawData).length; i++) {
          const start = parseInt(rawData[`${i + 1}`].from[0].split(":")[0]);
          const end = parseInt(rawData[`${i + 1}`].to[0].split(":")[0]);
          for (let j = 0; j < end - start + 1; j++) {
            const time = j + start;
            intervals.push(`${time < 10 ? `0${time}` : time}:00`);
          }
        }
        setTimeIntervals(intervals);

        return axios({
          method: "GET",
          url: `https://pyct.based.design/wp-json/salon/api/v1/bookings/upcoming?hours=${(orderableNumOfDay +
            2) *
            24}`,
        });
      })
      .then((response) => {
        const rawItems = response.data.items;
        let rawBookings = {};
        for (let i = 0; i < rawItems.length; i++) {
          if (!rawBookings[rawItems[i].date])
            rawBookings[rawItems[i].date] = {};
          if (
            !rawBookings[rawItems[i].date][rawItems[i].time] &&
            intervals.indexOf(rawItems[i].time) !== -1
          ) {
            rawBookings[rawItems[i].date][rawItems[i].time] = 0;

            rawBookings[rawItems[i].date].isWeekend = rawItems[i].isWeekend;
            rawBookings[rawItems[i].date][rawItems[i].time] += 1;
          }
        }

        let rawExcludeDates = [];
        Object.keys(rawBookings).map((key) => {
          const maxBookingNum =
            intervals.length *
            (rawBookings[key].isWeekend
              ? rules.weekend.unit
              : rules.weekday.unit);
          let totalBookings = 0;
          Object.keys(rawBookings[key])
            .filter((k) => k !== "isWeekend")
            .map((subKey) => (totalBookings += rawBookings[key][subKey]));

          if (totalBookings >= maxBookingNum) {
            rawBookings[key].isFull = true;
            rawExcludeDates.push(new Date(key));
          }
        });

        let defaultDate = info.date;
        let defaultTime = intervals[0];
        while (true) {
          const formattedDefaultDate = format(defaultDate, "yyyy-MM-dd");
          const isFinalDate =
            formattedDefaultDate === format(maxDate, "yyyy-MM-dd");

          if (!rawBookings[formattedDefaultDate]) break;

          const maxBookingNum = rawBookings[formattedDefaultDate].isWeekend
            ? rules.weekend.unit
            : rules.weekday.unit;
          if (
            rawBookings[formattedDefaultDate] &&
            rawBookings[formattedDefaultDate].isFull
          ) {
            defaultDate = addDays(defaultDate, 1);
            defaultTime = intervals[0];
          } else {
            let found = false;
            for (let j = 0; j < intervals.length; j++) {
              if (
                !rawBookings[formattedDefaultDate][intervals[j]] ||
                rawBookings[formattedDefaultDate][intervals[j]] < maxBookingNum
              ) {
                defaultTime = intervals[j];
                found = true;
                break;
              }
            }
            if (found) break;
          }

          if (isFinalDate) {
            setUnbookable(true);
            break;
          }
        }

        setInfo((prevState) => ({
          ...prevState,
          date: defaultDate,
          time: defaultTime,
        }));
        setBookings(rawBookings);
        setExcludeDates(rawExcludeDates);
      })
      .catch((response) => {
        console.log(response);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (options[activeSection]) {
      setActiveIntroIndex(null);

      let width = window.innerWidth,
        height = window.innerHeight,
        nodePadding = 2,
        baseRadius = isMobile ? 40 : 60;

      let wrapper = d3
        .select("section.active")
        .append("div")
        .attr("class", "q_list")
        .attr("style", `width: ${width}px; height: ${height}px`);

      const graph = Object.keys(options[activeSection]).map((item, i) => ({
        radius: baseRadius + (i % 3) * 10,
        value: item,
        label:
          item === "null" ? "立即預約" : options[activeSection][item].label,
        index: i - 1,
        color:
          COLORS[
            Object.keys(COLORS)[
              Math.floor(Math.random() * Object.keys(COLORS).length)
            ]
          ],
      }));

      var simulation = d3
        .forceSimulation(graph)
        // .alphaTarget(0.4)
        // .velocityDecay(0.1)
        .force(
          "x",
          d3
            .forceX()
            .strength(0.5)
            .x(width * 0.5)
        )
        .force(
          "y",
          d3
            .forceY()
            .strength(0.5)
            .y(height * 0.5)
        )
        .force(
          "center",
          d3
            .forceCenter()
            .x(width * 0.5)
            .y(height * 0.5)
        )
        .force(
          "collision",
          d3
            .forceCollide()
            .radius((d) => d.radius + nodePadding)
            .iterations(2)
        )
        .force(
          "charge",
          d3.forceManyBody().strength((d, i) => (i ? 0 : (-width * 2) / 3))
        )
        .on("tick", ticked);

      function ticked() {
        wrapper
          .selectAll("div.q_item")
          .data(graph)
          .enter()
          .append("div")
          // .attr("class", "q_item")
          .attr("class", (d) => `q_item color-${d.color}`)
          // .attr("style", d => `width: ${d.radius * 2}px; height: ${d.radius * 2}px; left: ${d.x}px; top: ${d.y}px;`)
          .style("width", (d) => `${d.radius * 2}px`)
          .style("height", (d) => `${d.radius * 2}px`)
          .style("left", (d) => `${d.x}px`)
          .style("top", (d) => `${d.y}px`)
          .style("transition", isSafari ? "none" : ".2s")
          .call(
            d3
              .drag()
              .on("start", dragstarted)
              .on("drag", dragged)
              .on("end", dragended)
          )
          .on("click", clicked)
          .on("mouseout", (d) => {
            if (!isSafari) mouseout(d);
          })
          // .on("pointermove", pointed)
          .on("touchmove", (e) => e.preventDefault())
          .on("mouseover", mouseover)

          .append("span")
          .text(function(d) {
            return d.label;
          });

        wrapper
          .selectAll("div.q_item")
          // .attr("style", d => `width: ${d.radius * 2}px; height: ${d.radius * 2}px; left: ${d.x}px; top: ${d.y}px;`)
          // .style('width', d => `${d.radius * 2}px`)
          // .style('height', d => `${d.radius * 2}px`)
          .style("left", (d) => `${d.x}px`)
          .style("top", (d) => `${d.y}px`);
      }

      function dragstarted(d) {
        if (!d3.event.active) simulation.alphaTarget(0.03).restart();
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }

      function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }

      function dragended(d) {
        if (!d3.event.active) simulation.alphaTarget(0.03);
        d.fx = null;
        d.fy = null;
      }

      function clicked(d) {
        if (d3.event.defaultPrevented) return;

        if (d3.event.target.className.indexOf("active") !== -1) {
          d3.event.target.classList.remove("active");
        } else {
          d3.event.target.classList.add("active");
        }

        handleSelectChange(activeSection, d.value);
      }

      function mouseout(d) {
        if (!d3.event.active) simulation.alphaTarget(0.03).restart();
        // d.x = d3.event.x - width / 200;
        // d.y = d3.event.y - height / 200;
        // d.x = d.x - width / 50;
        // d.y = d.y - height / 50;

        // console.log(d3.event)
        let xPlusOrMinus = Math.random() < 0.5 ? -1 : 1;
        let yPlusOrMinus = Math.random() < 0.5 ? -1 : 1;
        d.x = d.x + (xPlusOrMinus * width) / 12;
        d.y = d.y + (yPlusOrMinus * height) / 12;
        // graph[0].fx = d.x + xPlusOrMinus * width / 18;
        // graph[0].fy = d.y + yPlusOrMinus * height / 18;

        // // wrapper
        // //   .selectAll("div.q_item")
        // //   .style('left', `${d.x + xPlusOrMinus * width / 18}px`)
        // //   .style('top', `${d.y + yPlusOrMinus * height / 18}px`)
        // d3.event.toElement
        //   .style('left', `${d.x + xPlusOrMinus * width / 18}px`)
        //   .style('top', `${d.y + yPlusOrMinus * height / 18}px`)

        // let newX = d.x + (Math.random() < 0.5 ? -1 : 1) * width / 18;
        // let newY = d.y + (Math.random() < 0.5 ? -1 : 1) * height / 18;
        // wrapper.selectAll("div.q_item")
        //   .style('left', d => `${newX}px`)
        //   .style('top', d => `${newY}px`)
        // console.log(d3.event, newX, newY)
        // d3.event.target
        //   .style('left', d => `${newX}px`)
        //   .style('top', d => `${newY}px`)
      }

      function mouseover(d) {
        if (d3.event.defaultPrevented) return;

        // console.log
        // setActiveIntroIndex(d.index);
        setActiveIntroIndex(JSON.parse(d.value));
      }
    }
  }, [activeSection]);

  const handleInputChange = (e, type) => {
    const value = e.target.value;
    setInfo((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  };

  const handleSelectChange = (type, rawValue) => {
    setInfo((prevState) => {
      let currentValue = prevState[type];

      if (Object.keys(options).indexOf(type) !== -1) {
        if (rawValue == "null") setActiveSection(SECTION.BOOKING_FORM);
        else {
          if (type === "style" || type === "activity") {
            // 複選
            if (currentValue && currentValue.length !== 0) {
              const index = currentValue.indexOf(rawValue);
              if (index === -1) {
                currentValue = [...currentValue, rawValue];
              } else {
                currentValue.splice(index, 1);
              }
            } else {
              currentValue = [rawValue];
            }
          } else {
            // 單選
            currentValue = rawValue;

            // if (type === "view") setActiveSection(SECTION.QUESTION_B);
            // if (type === "num") setActiveSection(SECTION.QUESTION_C);
            // if (type === "activity") setActiveSection(SECTION.QUESTION_D);
            // if (type === "style") setActiveSection(SECTION.QUESTION_E);
            // if (type === "price") setActiveSection(SECTION.BOOKING_FORM);
          }
        }
      }

      return {
        ...prevState,
        [type]: currentValue,
      };
    });
  };

  const handleInputToggle = (e, type) => {
    setInfo((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleDateSelect = (newDate) => {
    const newFormattedDate = format(newDate, "yyyy-MM-dd");
    const maxBookingNum =
      newDate.getDay() === 0 || newDate.getDay() === 6
        ? rules.weekend.unit
        : rules.weekday.unit;

    let newTime = info.time;
    if (
      bookings[newFormattedDate] &&
      !bookings[newFormattedDate].isFull &&
      bookings[newFormattedDate][newTime] >= maxBookingNum
    ) {
      for (let i = 0; i < timeIntervals.length; i++) {
        if (
          !bookings[newFormattedDate][timeIntervals[i]] ||
          (bookings[newFormattedDate][timeIntervals[i]] &&
            bookings[newFormattedDate][timeIntervals[i]] < maxBookingNum)
        ) {
          newTime = timeIntervals[i];
          break;
        }
      }
    }

    setInfo((prevState) => ({ ...prevState, date: newDate, time: newTime }));
  };

  const submit = () => {
    // console.log(
    //   `可預約時間:${options.bookable[info.bookable]}` +
    //   `|樓層:${options.floor[info.floor]}` +
    //   `|居住人數:${options.num[info.num]}` +
    //   `|景色:${options.view[info.view]}` +
    //   `|活動:${(info.activity && info.activity!=='null') ? info.activity.map((item, i) => options.activity[item] ) : options.activity[info.activity]}` +
    //   `|建築風格:${(info.style && info.style!=='null') ? info.style.map((item, i) => options.style[item] ) : options.style[info.style]}` +
    //   `|每坪單價:${options.price[info.price]}`
    // )
    if (
      info.agreement &&
      info.name.trim() !== "" &&
      info.email.trim() !== "" &&
      info.phone.trim() !== "" &&
      isValidEmail(info.email)
    ) {
      setSubmitting(true);
      axios({
        method: "POST",
        url: "https://pyct.based.design/wp-json/salon/api/v1/bookings",
        data: {
          date: format(info.date, "yyyy-MM-dd"),
          time: info.time,
          status: "sln-b-paid",
          customer_first_name: info.name,
          customer_email: info.email,
          customer_phone: info.phone,
          note:
            `可預約時間:${options.bookable[info.bookable].label}` +
            `|樓層:${options.floor[info.floor].label}` +
            `|居住人數:${options.num[info.num].label}` +
            `|景色:${options.view[info.view].label}` +
            `|活動:${
              info.activity && info.activity !== "null"
                ? info.activity.map((item, i) => options.activity[item].label)
                : options.activity[info.activity].label
            }` +
            `|建築風格:${
              info.style && info.style !== "null"
                ? info.style.map((item, i) => options.style[item].label)
                : options.style[info.style].label
            }` +
            `|每坪單價:${options.price[info.price].label}`,
        },
      })
        .then(function(response) {
          //handle success
          // console.log(response);
          setSuccessed(true);
        })
        .catch(function(error) {
          // console.log('errrro')
          // console.log(error.response.data.data);
          if (
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.params &&
            error.response.data.data.params.customer_email
          )
            setErrorMessage((prevState) => [
              "您提供的 email 仍有問題，請協助檢查並再次送出。",
            ]);
          else
            setErrorMessage((prevState) => [
              "發生了一些錯誤，請協助檢查您的網路訊號是否正常，或稍等系統修復，再次提交預約。",
            ]);
        })
        .then(() => {
          setSubmitting(false);
        });
    } else {
      setCheckForm(true);
    }
  };

  const isValidEmail = (mail) => {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    )
      return true;
    else return false;
  };

  const renderItemIntro = () =>
    options[activeSection] &&
    options[activeSection][`${activeIntroIndex}`] &&
    activeIntroIndex !== null
      ? options[activeSection][`${activeIntroIndex}`].intro
      : "";

  return (
    <>
      <div className="booking_container">
        {/* <div className="section_bg enter">
        <div className="text text_a"></div>
        <div className="text text_b"></div>
      </div> */}
        <section
          className={`booking_section intro ${
            activeSection === SECTION.INTRO ? "active" : ""
          }`}
        >
          <div className="intro_content">
            <p>您好</p>
            <p>你想找什麼樣的知山呢？</p>
            <p>讓管家來幫你想想辦法</p>
            <p>尋找屬於你的知山。</p>
            <a
              className="start_booking"
              onClick={() => {
                setActiveSection(SECTION.QUESTION_A);
                // setInfo((prevState) => ({ ...prevState, view: "ocean" }));
              }}
            >
              開始預約
            </a>
          </div>
        </section>
        <section
          className={`booking_section question question_a ${
            activeSection === SECTION.QUESTION_A ? "active" : ""
          }`}
        >
          <div className="q_title">
            <p>放空一下喝杯咖啡，選個舒服的風景吧！</p>
          </div>
          <div
            className="form_next_btn"
            onClick={() => setActiveSection(SECTION.QUESTION_B)}
          >
            下一題
          </div>
          <div className="q_item_intro">
            {/* <p>白日依山盡，淡水入海流，欲窮千里目，先買一層樓。</p> */}
            <p>{renderItemIntro()}</p>
          </div>
        </section>
        <section
          className={`booking_section question question_b ${
            activeSection === SECTION.QUESTION_B ? "active" : ""
          }`}
        >
          <div className="q_title">
            <p>有了喜歡的風景，想跟誰一起分享呢？</p>
          </div>
          <div
            className="form_next_btn"
            onClick={() => setActiveSection(SECTION.QUESTION_C)}
          >
            下一題
          </div>
          <div className="q_item_intro">
            {/* <p>眾樂樂不如獨樂樂嘛!</p> */}
            <p>{renderItemIntro()}</p>
          </div>
        </section>
        <section
          className={`booking_section question question_c ${
            activeSection === SECTION.QUESTION_C ? "active" : ""
          }`}
        >
          <div className="q_title">
            <p>找點事來做做，選個好上手的活動？</p>
          </div>
          <div
            className="form_next_btn"
            onClick={() => setActiveSection(SECTION.QUESTION_D)}
          >
            下一題
          </div>
          <div className="q_item_intro">
            {/* <p>不管今天你過得有多鳥，芝山岩都有更多畫眉和五色鳥與你同在。</p> */}
            <p>{renderItemIntro()}</p>
          </div>
        </section>
        <section
          className={`booking_section question question_d ${
            activeSection === SECTION.QUESTION_D ? "active" : ""
          }`}
        >
          <div className="q_title">
            <p>任性的選擇喜歡的建築風格吧！</p>
          </div>
          <div
            className="form_next_btn"
            onClick={() => setActiveSection(SECTION.QUESTION_E)}
          >
            下一題
          </div>
          <div className="q_item_intro">
            {/* <p>（待上稿）</p> */}
            <p>{renderItemIntro()}</p>
          </div>
        </section>
        <section
          className={`booking_section question question_e ${
            activeSection === SECTION.QUESTION_E ? "active" : ""
          }`}
        >
          <div className="q_title">
            <p>來點實際的，預計負擔的每坪單價是多少？</p>
          </div>
          <div
            className="form_next_btn"
            onClick={() => setActiveSection(SECTION.BOOKING_FORM)}
          >
            下一題
          </div>
          <div className="q_item_intro">
            {/* <p>（待上稿）</p> */}
            <p>{renderItemIntro()}</p>
          </div>
        </section>
        {/* <section className={`booking_section question question_f ${activeSection===SECTION.QUESTION_F ? 'active' : ''}`}>
        <div className="q_title">
          <p>（待上稿）</p>
        </div>
        <div className="q_item_intro">
          <p>（待上稿）</p>
        </div>
      </section> */}

        {!unbookable ? (
          <>
            <section
              className={`booking_section booking_form ${
                activeSection === SECTION.BOOKING_FORM ? "active" : ""
              }`}
            >
              <div className="form_start_note">
                <p className="main">管家收到您的需求</p>
                <p>
                  只要再一步，完成線上預約，讓璞園知山完成你理想中的生活吧。
                </p>
                <div className="form_next_btn last_step_btn">下一步</div>
              </div>
              <h6 className="title">選擇您的賞屋時間</h6>
              {successed ? (
                <div class="success_notice">
                  <p>感謝您的預約！</p>
                  <p>您的預約資訊已寄至您的聯絡信箱</p>
                </div>
              ) : (
                ""
              )}
              <div className="form_wrap">
                <div className="form_row">
                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      日期
                    </label>
                    <DatePicker
                      selected={info.date}
                      onSelect={handleDateSelect} //when day is clicked
                      // onChange={handleDateChange} //only when value has changed
                      minDate={minDate}
                      maxDate={maxDate}
                      excludeDates={excludeDates}
                      disabled={loading || successed}
                    />
                  </div>

                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      時間
                    </label>
                    <select
                      id="time"
                      name="time"
                      onChange={(e) => handleInputChange(e, "time")}
                      value={info.time}
                      disabled={loading || successed}
                    >
                      {(!bookings[formattedDate] ||
                        (bookings[formattedDate] &&
                          !bookings[formattedDate].isFull)) &&
                        timeIntervals.map((timeInterval, i) => {
                          const maxBookingNum =
                            info.date.getDay() === 0 || info.date.getDay() === 6
                              ? rules.weekend.unit
                              : rules.weekday.unit;
                          if (
                            !bookings[formattedDate] ||
                            !bookings[formattedDate][timeInterval] ||
                            (bookings[formattedDate] &&
                              bookings[formattedDate][timeInterval] <
                                maxBookingNum)
                          )
                            return (
                              <option key={i} value={timeInterval}>
                                {timeInterval}
                              </option>
                            );
                        })}
                    </select>
                  </div>

                  <div className="col col_s">
                    <label htmlFor="time" className="form_label">
                      樓層
                    </label>
                    <select
                      id="floor"
                      name="floor"
                      onChange={(e) => handleInputChange(e, "floor")}
                      value={info.floor}
                      disabled={successed}
                    >
                      <option value="0">1樓</option>
                      <option value="1">2樓</option>
                      <option value="2">3樓</option>
                      <option value="3">4樓</option>
                      <option value="4">5樓</option>
                      <option value="5">6樓</option>
                      <option value="6">7樓</option>
                      <option value="7">8樓</option>
                      <option value="8">9樓</option>
                      <option value="9">10樓</option>
                      <option value="10">11樓</option>
                      <option value="11">12樓</option>
                      <option value="12">13樓</option>
                      <option value="13">14樓</option>
                    </select>
                  </div>
                </div>

                <div className="form_row">
                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      姓名
                    </label>
                    <input
                      type="text"
                      placeholder="姓名"
                      onChange={(e) => handleInputChange(e, "name")}
                      disabled={successed}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      聯絡電話
                    </label>
                    <input
                      type="text"
                      placeholder="聯絡電話"
                      onChange={(e) => handleInputChange(e, "phone")}
                      inputMode="tel"
                      disabled={successed}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      可聯絡時段
                    </label>
                    <select
                      id="bookable"
                      name="bookable"
                      onChange={(e) => handleInputChange(e, "bookable")}
                      value={info.bookable}
                      disabled={successed}
                    >
                      {Object.keys(options.bookable).map((key, i) => {
                        // console.log(options.bookable[key]);
                        return(
                        <option value={key} key={i}>
                          {options.bookable[key]}
                        </option>
                      )})}
                    </select>
                  </div>
                </div>

                <div className="form_row">
                  <div className="col">
                    <label htmlFor="time" className="form_label">
                      聯絡信箱
                    </label>
                    <input
                      type="text"
                      placeholder="聯絡信箱"
                      inputMode="email"
                      onChange={(e) => handleInputChange(e, "email")}
                      disabled={successed}
                    />
                  </div>
                </div>

                <div className="form_row">
                  <label htmlFor="agreement" disabled={successed}>
                    <input
                      id="agreement"
                      type="checkbox"
                      onClick={(e) => handleInputToggle(e, "agreement")}
                    />
                    我同意提供以上資訊
                  </label>
                </div>

                {!successed ? (
                  <ul className="form_note">
                    {checkForm && info.name.trim() === "" ? (
                      <li>請填寫您的名字</li>
                    ) : (
                      ""
                    )}
                    {checkForm && info.email.trim() === "" ? (
                      <li>請填寫您的 email</li>
                    ) : (
                      ""
                    )}
                    {checkForm &&
                    info.email.trim() !== "" &&
                    !isValidEmail(info.email) ? (
                      <li>請填寫正確格式的 email</li>
                    ) : (
                      ""
                    )}
                    {checkForm && info.phone.trim() === "" ? (
                      <li>請填寫您的聯絡電話</li>
                    ) : (
                      ""
                    )}
                    {checkForm && !info.agreement ? (
                      <li>請勾選同意以上資訊</li>
                    ) : (
                      ""
                    )}
                    {errorMessage.length !== 0 ? (
                      <li>{errorMessage[0]}</li>
                    ) : (
                      ""
                    )}
                  </ul>
                ) : (
                  ""
                )}

                <div className="form_row">
                  <button
                    className="submit"
                    onClick={submit}
                    disabled={loading || submitting || successed}
                  >
                    送出預約
                  </button>
                </div>
              </div>
            </section>
          </>
        ) : (
          <div style={{ color: "red" }}>暫無可預定時間</div>
        )}
      </div>
      <Helmet
        title="賞屋預約・璞園知山・知己然後知山・璞園建築團隊"
        meta={[
          {
            name: `description`,
            content:
              "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
          },
          {
            property: `og:title`,
            content: "賞屋預約・璞園知山・知己然後知山・璞園建築團隊",
          },
          {
            property: `og:description`,
            content:
              "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            // content:
            // "https://online.home-to-all.com" +
            // require("../images/422socialimage.jpg"),
          },
        ]}
      ></Helmet>
    </>
  );
};

export default Page;
