import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import { Router } from "@reach/router";
import Helmet from "react-helmet";
import Link from "../LinkWithTransitionLock";
import InnerHeight from "ios-inner-height";

import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // setTimeout(() => {
    //   var url_string = window.location.pathname;
    //   if (url_string == "/select" || url_string == "/select/") {
    //     // console.log("building");
    //     document.querySelector(".select_floor").style.display = "block";
    //     var header = document.querySelector(".header");
    //     header.classList.remove("hide");
    //     header.classList.remove("inner");
    //     document.querySelector("body").classList.add("overflow");

    //     gsap.to(".building_photo", {
    //       opacity: 1,
    //       delay: 1.2,
    //       ease: Power1.easeOut,
    //     });
    //   }
    // }, 1000);

    document.querySelector("#wrap").classList.add("overlay_control");

    var url_string = window.location.pathname;
    // console.log("0-" + url_string);
    if (url_string == "/select" || url_string == "/select/") {
      // console.log("1-" + url_string);
      var time = window.setInterval(() => {
        if (!document.querySelector(".select_floor").getAttribute("style")) {
          // console.log("building");
          document.querySelector(".select_floor").style.display = "block";
          var header = document.querySelector(".header");
          header.classList.remove("hide");
          header.classList.remove("inner");
          floor_text.classList.remove("hide");
          document.querySelector("body").classList.add("overflow");

          gsap.to(".building_photo", {
            opacity: 1,
            // delay: 1.2,
            ease: Power1.easeOut,
          });
          // console.log("1");
          clearInterval(time);
        } else {
          // console.log("0");
        }
      }, 500);
    }

    var floor_frame = document.querySelector(".floor_frame");
    var select_floor = document.querySelector(".select_floor");
    var select_note = document.querySelector(".select_note");
    var floor_text = document.querySelector(".floor_text");
    var floor_text_num = document.querySelector(".floor_text .num");
    var floor_trigger = document.querySelector(".floor_trigger");
    var floor_trigger_fl = document.querySelectorAll(".floor_trigger .fl");
    var floor_layer = document.querySelectorAll("svg .layer");
    var layer = document.querySelectorAll(".layer");
    // var building_floor_frame = document.querySelector(".building_floor_frame");

    // var building_selector = document.querySelector(".building_selector");
    // // building_floor_frame.style.width = window.innerWidth + "px";
    // building_floor_frame.style.height =
    //   building_selector.getBoundingClientRect().width * 0.5625 + "px";
    // var floor = document.querySelectorAll(".floor");
    var clientX, clientY;

    floor_trigger.addEventListener("touchmove", function(e) {
      e.preventDefault();
      floor_trigger_fl.forEach(function(element) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
        if (
          element.getBoundingClientRect()["top"] < clientY &&
          element.getBoundingClientRect()["bottom"] > clientY
        ) {
          element.classList.add("enter");
          var floor_fl = element.dataset.fl;
          var floor_num = element.dataset.num;

          floor_text.style.top = element.getBoundingClientRect()["top"] + "px";
          floor_text_num.textContent = floor_num;
          // console.log(floor_num);
          layer.forEach(function(element_2) {
            if (element_2.classList.contains(floor_fl)) {
              element_2.classList.add("enter");
            } else {
              element_2.classList.remove("enter");
            }
          });
        } else {
          element.classList.remove("enter");
        }
      });
    });

    floor_trigger.addEventListener("touchstart", function(e) {
      // e.preventDefault();
      select_note.classList.add("hide");
      floor_text.classList.remove("hide");
      floor_trigger_fl.forEach(function(element) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
        if (
          element.getBoundingClientRect()["top"] < clientY &&
          element.getBoundingClientRect()["bottom"] > clientY
        ) {
          element.classList.add("enter");
          var floor_num = element.dataset.fl;
          // console.log(floor_num);
          layer.forEach(function(element_2) {
            if (element_2.classList.contains(floor_num)) {
              element_2.classList.add("enter");
            } else {
              element_2.classList.remove("enter");
            }
          });
        } else {
          element.classList.remove("enter");
        }
      });
    });
    floor_trigger.addEventListener("touchend", function(e) {
      // e.preventDefault();
      // select_note.classList.remove("hide");
      // floor_text.classList.add("hide");
    });

    floor_layer.forEach(function(element) {
      element.addEventListener("mousemove", function(e) {
        var floor_num = element.dataset.num;
        floor_text.style.top = element.getBoundingClientRect()["top"] + "px";
        select_note.classList.add("hide");
        floor_text.classList.remove("hide");
        floor_text_num.textContent = floor_num;
      });
      element.addEventListener("mouseout", function(e) {
        select_note.classList.remove("hide");
        floor_text.classList.add("hide");
      });
    });
  }

  componentWillUnmount() {
    // document.querySelector("#wrap").remove.add("overlay_control");
  }

  render() {
    return (
      <>
        <Helmet
          title="建築平面・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "建築平面・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        ></Helmet>

        <section className="select_floor">
          <div className="floor_text hide">
            <div className="num"></div>
          </div>
          <div className="select_note">
            <p className="main">滑動選擇樓層</p>
            {/* <p className="sub">滑動選擇</p> */}
          </div>
          <div className="building_selector">
            <div
              className="building_photo"
              style={{
                backgroundImage: `url(${require("../../images/building_all_2.jpg")})`,
              }}
            ></div>
            <div className="floor_info"></div>
            <div className="building_floor_frame">
              <div className="floor_trigger">
                {/* <a href="../floor/F14" className="fl f15" data-fl="f15"></a> */}
                <Link
                  to="../floor/F14"
                  className="fl f14"
                  data-fl="f14"
                  data-num="14"
                ></Link>
                <Link
                  to="../floor/F13/"
                  className="fl f13"
                  data-fl="f13"
                  data-num="13"
                ></Link>
                <Link
                  to="../floor/F12/"
                  className="fl f12"
                  data-fl="f12"
                  data-num="12"
                ></Link>
                <Link
                  to="../floor/F11/"
                  className="fl f11"
                  data-fl="f11"
                  data-num="11"
                ></Link>
                <Link
                  to="../floor/F10/"
                  className="fl f10"
                  data-fl="f10"
                  data-num="10"
                ></Link>
                <Link
                  to="../floor/F9/"
                  className="fl f9"
                  data-fl="f9"
                  data-num="9"
                ></Link>
                <Link
                  to="../floor/F8/"
                  className="fl f8"
                  data-fl="f8"
                  data-num="8"
                ></Link>
                <Link
                  to="../floor/F7/"
                  className="fl f7"
                  data-fl="f7"
                  data-num="7"
                ></Link>
                <Link
                  to="../floor/F6/"
                  className="fl f6"
                  data-fl="f6"
                  data-num="6"
                ></Link>
                <Link
                  to="../floor/F5/"
                  className="fl f5"
                  data-fl="f5"
                  data-num="5"
                ></Link>
                <Link
                  to="../floor/F4/"
                  className="fl f4"
                  data-fl="f4"
                  data-num="4"
                ></Link>
                <Link
                  to="../floor/F3/"
                  className="fl f3"
                  data-fl="f3"
                  data-num="3"
                ></Link>
                <Link
                  to="../floor/F2/"
                  className="fl f2"
                  data-fl="f2"
                  data-num="2"
                ></Link>
                <Link
                  to="../floor/F1/"
                  className="fl f1"
                  data-fl="f1"
                  data-num="1"
                ></Link>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1920"
                height="1080"
                viewBox="0 0 1920 1080"
                className="floor_frame"
              >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                  <g>
                    <Link to="../floor/F1/">
                      <path
                        className="layer f1"
                        data-num="1"
                        d="M1053.7213 801.6245L1023.8163 822.5365 1004.4733 807.2805 970.4183 829.6205 958.3913 821.4475 937.7553 835.7095 888.1873 803.0165 887.3483 769.0795 852.3733 742.4395 852.3733 734.8115 830.9103 718.1615 823.8463 721.7345 817.1033 717.0075 809.9943 712.0235 809.9943 717.1035 783.7983 729.6355 783.7983 746.5265 765.0003 758.5145 974.0913 916.5295 1126.7973 810.5495 1128.4323 791.2065 1064.6823 750.3415 1062.7753 795.2935z"
                        data-floor="1"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F2/">
                      <path
                        className="layer f2"
                        data-num="2"
                        d="M1064.4098 758.2417L1025.1788 787.1197 1006.6528 774.0437 971.5088 795.2937 958.1588 784.3957 937.4528 798.0177 887.5978 766.9597 886.5078 738.0817 852.3738 713.8347 852.3738 742.4397 887.3478 769.0797 888.1868 803.0157 937.7558 835.7097 958.3908 821.4477 970.4188 829.6207 1004.4728 807.2807 1023.8158 822.5367 1062.7748 795.2937z"
                        data-floor="2"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F3/">
                      <path
                        className="layer f3"
                        data-num="3"
                        d="M1065.7711 724.6411L1025.4511 751.8851 1007.2891 742.4401 970.6001 762.0561 957.5231 750.7951 934.2751 762.0561 886.2651 732.6351 885.5991 704.6621 841.3881 673.7901 820.7131 684.5471 794.2491 667.1081 797.4201 703.2091 823.8471 721.7351 847.0041 710.0201 886.5071 738.0811 887.5971 766.9591 937.4531 798.0181 958.1581 784.3961 971.5081 795.2931 1006.6531 774.0431 1025.1781 787.1201 1064.4101 758.2421z"
                        data-floor="3"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F4/">
                      <path
                        className="layer f4"
                        data-num="4"
                        d="M1067.4674 692.6753L1025.8144 718.1023 1007.6514 708.2943 970.3174 729.6353 957.2334 718.8393 935.2734 729.6353 884.8724 699.5763 884.5094 671.9693 841.2824 641.0933 818.0344 650.9013 792.4464 636.3773 794.2494 667.1083 820.7134 684.5473 841.3874 673.7903 885.5994 704.6623 886.2654 732.6353 934.2754 762.0563 957.5234 750.7953 970.6004 762.0563 1007.2884 742.4403 1025.4504 751.8853 1065.7714 724.6413z"
                        data-floor="4"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F5/">
                      <path
                        className="layer f5"
                        data-num="5"
                        d="M1069.7672 659.2554L1026.5402 684.3194 1007.2882 673.0594 970.2372 692.6754 958.3632 684.5084 958.0682 663.7964 948.7302 656.7954 934.8202 664.1594 903.3982 643.8994 900.1292 647.2694 868.5262 626.9264 840.7382 608.1284 816.6272 618.2084 788.6112 601.1354 791.1542 635.6454 818.0352 650.9014 841.2832 641.0934 884.5092 671.9694 884.8722 699.5764 935.2732 729.6354 957.2332 718.8394 970.3172 729.6354 1007.6512 708.2944 1025.8142 718.1024 1067.4672 692.6754z"
                        data-floor="5"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F6/">
                      <path
                        className="layer f6"
                        data-num="6"
                        d="M1071.9469 624.2017L998.2069 665.6127 978.7729 654.1707 970.4189 659.4367 959.5209 651.0827 934.8199 664.1597 901.5819 642.7277 900.2089 612.5207 866.6829 593.9647 866.3469 566.2637 858.9009 561.1787 813.6759 584.7897 786.4009 566.8097 788.6119 601.1357 816.6269 618.2087 840.7379 608.1287 868.5269 626.9267 900.1289 647.2687 903.3989 643.8987 934.8199 664.1597 948.7309 656.7957 958.0679 663.7967 958.3639 684.5087 970.2369 692.6757 1007.2889 673.0587 1026.5409 684.3197 1069.7669 659.2557z"
                        data-floor="6"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F7/">
                      <path
                        className="layer f7"
                        data-num="7"
                        d="M1073.8082 587.1509L1000.1142 628.6979 979.9542 616.5739 971.3712 620.9329 958.5672 613.9859 935.9542 625.4279 900.6742 606.3579 900.6742 604.6549 879.3292 592.1219 879.0282 566.2739 866.2102 560.1789 866.3472 553.5049 849.1832 544.1049 848.0592 523.8709 839.2042 519.0949 804.2312 535.0239 811.8592 539.3829 785.7052 549.5539 786.4012 566.8099 813.6752 584.7899 849.6372 566.0829 858.9002 561.1779 866.3472 566.2629 866.6832 593.9639 900.2092 612.5209 901.5822 642.7279 934.8202 664.1599 959.5202 651.0829 970.4182 659.4369 978.7732 654.1709 998.2062 665.6129 1071.9472 624.2019z"
                        data-floor="7"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F8/">
                      <path
                        className="layer f8"
                        data-num="8"
                        d="M847.004 504.8745L863.974 513.4585 863.91 519.3685 888.142 534.0255 888.233 539.8825 878.062 548.7365 878.607 556.0925 921.107 579.2495 944.264 568.3525 972.053 581.9745 981.316 576.7985 999.841 586.3335 1063.362 555.8855 1063.362 571.7585 1073.808 582.1105 1073.808 587.1505 1000.114 628.6975 979.954 616.5735 971.372 620.9335 958.567 613.9855 935.955 625.4285 900.674 606.3575 900.674 604.6545 879.33 592.1215 879.028 566.2735 866.211 560.1795 866.347 553.5045 849.183 544.1045z"
                        data-floor="8"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F9/">
                      <path
                        className="layer f9"
                        data-num="9"
                        d="M844.1886 465.2798L862.0786 473.5438 862.1696 478.5388 866.3466 480.8998 866.7096 508.5068 888.3236 519.7678 888.3236 522.6738 921.5616 539.5648 946.4436 528.6668 972.0536 543.1968 994.9386 532.6628 1012.3736 543.1968 1067.4676 517.4058 1065.7716 554.7298 999.8416 586.3338 981.3156 576.7988 972.0536 581.9738 944.2646 568.3518 921.1066 579.2498 878.6066 556.0928 878.0616 548.7368 888.2326 539.8828 888.1426 534.0258 863.9096 519.3688 863.9746 513.4588 847.0036 504.8748z"
                        data-floor="9"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F10/">
                      <path
                        className="layer f10"
                        data-num="10"
                        d="M842.9171 431.0435L860.6251 438.6715 875.3371 446.0275 875.3371 455.0185 864.7121 462.6465 864.7121 470.0025 908.8471 490.7075 935.2731 479.8105 972.8701 497.7915 993.5761 486.8935 1012.7831 496.8375 1067.4671 473.7135 1067.4671 517.4065 1012.3741 543.1965 994.9381 532.6635 972.0531 543.1965 946.4441 528.6675 921.5611 539.5645 888.3241 522.6735 888.3241 519.7675 866.7101 508.5065 866.3471 480.8995 862.1691 478.5385 862.0791 473.5435 844.1881 465.2795z"
                        data-floor="10"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F11/">
                      <path
                        className="layer f11"
                        data-num="11"
                        d="M840.4655 385.0015L851.3625 390.1775 852.4525 419.8735 909.6645 447.3895 934.7295 437.5825 973.1425 456.3805 1006.1085 440.0345 1026.8135 450.1145 1082.3905 426.1395 1080.7555 468.0955 1012.7825 496.8375 993.5755 486.8935 972.8695 497.7915 935.2735 479.8105 908.8475 490.7075 864.7125 470.0025 864.7125 462.6465 875.3375 455.0185 875.3375 446.0275 860.6255 438.6715 842.9175 431.0435z"
                        data-floor="11"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F12/">
                      <path
                        className="layer f12"
                        data-num="12"
                        d="M838.8146 353.2251L859.5966 364.2111 850.3416 370.5621 850.6036 376.4351 861.7156 381.8691 883.4196 391.7221 894.6806 396.4441 922.4696 385.8191 972.5986 410.3381 1005.8356 395.5361 1027.0856 405.7071 1074.1186 386.3961 1072.3106 426.1401 1082.3906 420.9631 1082.3906 426.1401 1026.8136 450.1141 1006.1076 440.0341 973.1426 456.3801 934.7296 437.5821 909.6646 447.3901 852.4526 419.8731 851.3626 390.1781 840.4656 385.0011z"
                        data-floor="12"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F13/">
                      <path
                        className="layer f13"
                        data-num="13"
                        d="M835.2682 319.2466L893.9992 348.4946 920.8342 340.1856 973.5512 363.7516 1019.5472 345.3166 1040.1632 352.5816 1089.3952 329.8096 1096.5572 326.6996 1094.1052 378.1906 1027.0852 405.7066 1005.8352 395.5356 972.5982 410.3386 922.4692 385.8186 894.6802 396.4436 883.4192 391.7216 861.7152 381.8686 850.6032 376.4356 850.3412 370.5626 859.5962 364.2106 838.8142 353.2256z"
                        data-floor="13"
                        opacity="0.501"
                      ></path>
                    </Link>
                    <Link to="../floor/F14/">
                      <path
                        className="layer f14"
                        data-num="14"
                        d="M956.9781 314.1675L897.2231 289.4665 870.3431 298.0025 847.5071 289.7635 834.0531 284.9085 835.2681 319.2465 893.9991 348.4945 920.8341 340.1855 973.5511 363.7515 1019.5471 345.3165 1040.1631 352.5815 1089.3951 329.8095 1089.3951 292.5155 1040.8571 310.3915 1020.8191 303.4065 971.9161 319.8885z"
                        data-floor="14"
                        opacity="0.501"
                      ></path>
                    </Link>
                    {/* <a href="../floor/F15/">
                    <path
                      className="layer f15"
                      data-num="15"
                      d="M834.0529 284.9087L870.3429 298.0027 897.2229 289.4667 971.9169 319.8887 1020.8189 303.4067 1040.8569 310.3907 1089.3949 292.5157 1091.1079 250.6897 1069.5859 245.2407 1078.8479 241.1547 1017.8229 216.3627 1019.1839 154.5187 929.8249 129.9997 901.7639 136.8107 903.3989 202.7407 899.3119 203.0127 902.8539 205.4647 882.4209 211.4587 876.9719 209.0067 831.4749 220.7207 859.2639 233.7987 831.4749 241.6997z"
                      data-floor="15"
                      opacity="0.501"
                    ></path>
                  </a> */}
                    <path d="M0 0H1920V1080H0z"></path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Page;
