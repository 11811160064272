// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-archi-js": () => import("./../../../src/pages/archi.js" /* webpackChunkName: "component---src-pages-archi-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-floor-b-1-js": () => import("./../../../src/pages/floor/B1.js" /* webpackChunkName: "component---src-pages-floor-b-1-js" */),
  "component---src-pages-floor-b-2-js": () => import("./../../../src/pages/floor/B2.js" /* webpackChunkName: "component---src-pages-floor-b-2-js" */),
  "component---src-pages-floor-b-3-js": () => import("./../../../src/pages/floor/B3.js" /* webpackChunkName: "component---src-pages-floor-b-3-js" */),
  "component---src-pages-floor-f-1-js": () => import("./../../../src/pages/floor/F1.js" /* webpackChunkName: "component---src-pages-floor-f-1-js" */),
  "component---src-pages-floor-f-10-js": () => import("./../../../src/pages/floor/F10.js" /* webpackChunkName: "component---src-pages-floor-f-10-js" */),
  "component---src-pages-floor-f-11-js": () => import("./../../../src/pages/floor/F11.js" /* webpackChunkName: "component---src-pages-floor-f-11-js" */),
  "component---src-pages-floor-f-12-js": () => import("./../../../src/pages/floor/F12.js" /* webpackChunkName: "component---src-pages-floor-f-12-js" */),
  "component---src-pages-floor-f-13-js": () => import("./../../../src/pages/floor/F13.js" /* webpackChunkName: "component---src-pages-floor-f-13-js" */),
  "component---src-pages-floor-f-14-js": () => import("./../../../src/pages/floor/F14.js" /* webpackChunkName: "component---src-pages-floor-f-14-js" */),
  "component---src-pages-floor-f-2-js": () => import("./../../../src/pages/floor/F2.js" /* webpackChunkName: "component---src-pages-floor-f-2-js" */),
  "component---src-pages-floor-f-3-js": () => import("./../../../src/pages/floor/F3.js" /* webpackChunkName: "component---src-pages-floor-f-3-js" */),
  "component---src-pages-floor-f-4-js": () => import("./../../../src/pages/floor/F4.js" /* webpackChunkName: "component---src-pages-floor-f-4-js" */),
  "component---src-pages-floor-f-5-js": () => import("./../../../src/pages/floor/F5.js" /* webpackChunkName: "component---src-pages-floor-f-5-js" */),
  "component---src-pages-floor-f-6-js": () => import("./../../../src/pages/floor/F6.js" /* webpackChunkName: "component---src-pages-floor-f-6-js" */),
  "component---src-pages-floor-f-7-js": () => import("./../../../src/pages/floor/F7.js" /* webpackChunkName: "component---src-pages-floor-f-7-js" */),
  "component---src-pages-floor-f-8-js": () => import("./../../../src/pages/floor/F8.js" /* webpackChunkName: "component---src-pages-floor-f-8-js" */),
  "component---src-pages-floor-f-9-js": () => import("./../../../src/pages/floor/F9.js" /* webpackChunkName: "component---src-pages-floor-f-9-js" */),
  "component---src-pages-floor-js": () => import("./../../../src/pages/floor.js" /* webpackChunkName: "component---src-pages-floor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-planeasyfloor-js": () => import("./../../../src/pages/planeasyfloor.js" /* webpackChunkName: "component---src-pages-planeasyfloor-js" */),
  "component---src-pages-select-js": () => import("./../../../src/pages/select.js" /* webpackChunkName: "component---src-pages-select-js" */),
  "component---src-pages-view-24-m-js": () => import("./../../../src/pages/view_24m.js" /* webpackChunkName: "component---src-pages-view-24-m-js" */),
  "component---src-pages-view-37-m-js": () => import("./../../../src/pages/view_37m.js" /* webpackChunkName: "component---src-pages-view-37-m-js" */),
  "component---src-pages-view-50-m-js": () => import("./../../../src/pages/view_50m.js" /* webpackChunkName: "component---src-pages-view-50-m-js" */),
  "component---src-pages-view-js": () => import("./../../../src/pages/view.js" /* webpackChunkName: "component---src-pages-view-js" */),
  "component---src-pages-vrexperience-js": () => import("./../../../src/pages/vrexperience.js" /* webpackChunkName: "component---src-pages-vrexperience-js" */)
}

