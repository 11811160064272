import { createContext } from 'React'

// type ContextType = {
//   indexVideoIsPlayed: boolean,
//   setIndexVideoIsPlayed: (any) => void,
// }

const initState = {
  indexVideoIsPlayed: false,
  setIndexVideoIsPlayed: null,
}

export default createContext(initState)