import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";

import InteractionBgCanvas from "./InteractionBgCanvas.jsx";

const RouterHOCInteractionBgCanvas = ({ routerConfig, ...restProps }) => {
  const isInitialMount = useRef(true);
  const location = useLocation();

  const findLocationConfig = () => {
    return routerConfig.find(({ pathname }) => {
      // console.log(pathname, location.pathname);
      return location.pathname.includes(pathname);
    });
  };

  const [colorSettings, setColorSettings] = useState(findLocationConfig());
  const [isCleanTheCanvas, setIsCleanTheCanvas] = useState(false);

  const handleCloseCleanCanvas = () => {
    setIsCleanTheCanvas(false);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setColorSettings(findLocationConfig());
      setIsCleanTheCanvas(true);
    }
    return () => {};
  }, [location]);

  return (
    <InteractionBgCanvas
      {...restProps}
      {...colorSettings}
      isCleanTheCanvas={isCleanTheCanvas}
      handleCloseCleanCanvas={handleCloseCleanCanvas}
    />
  );
};

RouterHOCInteractionBgCanvas.defaultProps = {
  defaultBgColor: "white",
};

RouterHOCInteractionBgCanvas.propTypes = {
  defaultBgColor: PropTypes.string.isRequired,
  routerConfig: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      bgColor: PropTypes.string,
      strokeColor: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default RouterHOCInteractionBgCanvas;
