import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { Router, Link } from "@reach/router";

import InnerHeight from "ios-inner-height";

import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (navigator.userAgent.match(/iPhone/)) {
      document.querySelector("body").classList.add("iphone");
    }

    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.remove("inner");
    document.querySelector("body").classList.add("overflow");

    // var cover_video = document.querySelector(".cover_video");
    // cover_video.addEventListener("click", () => {
    //   cover_video.classList.toggle("play");
    // });

    if (!isMobile) {
      setTimeout(() => {
        document.querySelector(".cover_note").classList.add("hide");
      }, 5000);
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <div className="full_video_cover">
          {isMobile ? (
            <section className="section_container dark enter">
              <div className="inner_cover video">
                <h2 className="page_title">
                  <div className="text text_a">虛擬體驗</div>
                  <div className="text text_b">
                    使用 Youtube APP 體驗 360 虛擬樣品屋
                  </div>
                  <a
                    className="section_link"
                    href="https://youtu.be/msjDONRhUes"
                  >
                    <div className="label">開始體驗</div>
                    <i className="label_icon">→</i>
                  </a>
                </h2>
                <video
                  className="video"
                  autoPlay
                  loop
                  playsInline
                  muted="muted"
                  src={require("../../videos/inside_video_main_s3.mp4")}
                ></video>
              </div>
            </section>
          ) : (
            <div class="cover_video">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/msjDONRhUes?rel=0&autoplay=1"
                title="璞園知山・知己，然後知山"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div className="cover_note">
                <div className="note_icon"></div>
                <p>點擊與拖曳體驗知山</p>
              </div>
            </div>
          )}
        </div>

        <Helmet
          title="虛擬體驗・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "虛擬體驗・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        ></Helmet>
      </>
    );
  }
}

export default Page;
