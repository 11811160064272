/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import "./src/styles /reset.min.css"
// import "./node_modules/reset-css/reset.css";
// import "./src/styles/global.scss";
import "./src/styles/sass/all.scss";
