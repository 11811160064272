
import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import { Router, Link } from "@reach/router"

import InnerHeight from 'ios-inner-height'

import { isMobile } from "react-device-detect";
import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

import window from 'global'

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {

      // window.scrollTo(0,1)

      var cursor = document.querySelector(".cursor");

      window.addEventListener("mousemove", cursor_fun);
      window.addEventListener("touchmove", cursor_fun);

      function cursor_fun(event) {
         var event = event || window.event; 
         var cursor_animate = new TimelineMax();
         cursor_animate.to(cursor, 0.5, {
            left: event.clientX,
            top: event.clientY,
            ease: Power2.easeOut,
         });
      }
      
  }
 
  componentWillUnmount() {

  }


  render() {

      return (
         <div className="cursor">
           <div className="cursor_inner"></div>
           <div className="cursor_visual"></div>
         </div>
      )

   }

}


export default Page

  