//飲食: food
//餐廳: restaurant
//購物: shopping
//醫療資源: medical
//綠地: green
//學校: school

export const FOOD = "food";
export const RESTAURANT = "restaurant";
export const SHOPPING = "shopping";
export const MEDICAL = "medical";
export const GREEN = "green";
export const SCHOOL = "school";