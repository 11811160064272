import React, { useState, useCallback, useEffect } from "react";
// import Helmet from "react-helmet";
import loadable from "@loadable/component";
import { Router, Match } from "@reach/router";

import IndexSPA from "../components/IndexSPA";

// var og_img = 'https://online.home-to-all.com' + require('../images/422socialimage.jpg');
// var ios_icon = require('../images/ios_icon.png');
// var ms_icon = require('../images/ms_icon.png');

const Index = (props) => {
  return (
    <>
      {/* <Helmet
        title="璞園知山・知己然後知山・璞園建築團隊"
        meta={[
          {
            name: `description`,
            content:
              "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
          },
          {
            property: `og:title`,
            content: "璞園知山・知己然後知山・璞園建築團隊",
          },
          {
            property: `og:description`,
            content:
              "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            // content:
            // "https://online.home-to-all.com" +
            // require("../images/422socialimage.jpg"),
          },
        ]}
      ></Helmet> */}

      {/* <Page /> */}
    </>
  );
};

// Index.propTypes = {}

export default Index;
