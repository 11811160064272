import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import { Router } from "@reach/router";
import Link from "../LinkWithTransitionLock";
import Headroom from "headroom.js";
import InnerHeight from "ios-inner-height";

import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";
import { func } from "prop-types";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    var header = document.querySelector(".header");
    var nav = document.querySelector(".nav");
    var nav_list = document.querySelector(".nav_list");
    var nav_switch = document.querySelector(".nav_switch");
    if (window.innerWidth < 768) {
      nav_list.style.height = window.innerHeight + "px";
    }
    window.addEventListener("resize", function() {
      if (window.innerWidth < 768) {
        nav_list.style.height = window.innerHeight + "px";
      } else {
        nav_list.style.height = "auto";
        header.classList.remove("on");
      }
    });
    nav_switch.addEventListener("click", function() {
      header.classList.toggle("on");
      if (header.classList.contains("on")) {
        nav.style.height = window.innerHeight + "px";
        // gsap.set(".nav_list", {
        //   opacity: 1,
        //   // duration: 1,
        //   // ease: Power1.easeIn,
        // });
      } else {
        nav.style.height = "0px";
        // gsap.to(".nav_list", {
        //   opacity: 0,
        //   duration: 1,
        // });
      }
    });
    var nav_item_link = document.querySelectorAll(".nav_list .item_link");
    nav_item_link.forEach(function(element) {
      element.addEventListener("click", function() {
        nav.style.height = "0px";
        header.classList.remove("on");
      });
    });

    // var headroom = new Headroom(header);
    // headroom.init();
  }

  componentWillUnmount() {}

  render() {
    return (
      <header className="header hide">
        <div className="logo">
          <Link to="../" className="logo_inner">
            <h1>璞園知山</h1>
          </Link>
        </div>
        {/* <h2 className="page_title">
            <div className="text">建築特色</div>
          </h2> */}
        <div className="nav_switch">
          <div className="btn">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
        <nav className="nav">
          <ul className="nav_list">
            {/* <li className="list_item">
              <Link to="../" className="item_link">
                首頁
              </Link>
            </li> */}
            <li className="list_item">
              <Link to="../archi/" className="item_link">
                <div className="text">一層一戶</div>
              </Link>
            </li>
            <li className="list_item">
              <Link to="../planeasyfloor/" className="item_link">
                <div className="text">自由平面</div>
              </Link>
            </li>
            <li className="list_item">
              <Link to="../select/" className="item_link">
                <div className="text">選擇樓層</div>
              </Link>
            </li>
            <li className="list_item">
              <Link to="../view/" className="item_link">
                <div className="text">生活地圖</div>
              </Link>
            </li>
            <li className="list_item">
              <Link to="../vrexperience/" className="item_link">
                <div className="text">虛擬體驗</div>
              </Link>
            </li>
            <li className="list_item">
              <Link to="../booking/" className="item_link">
                <div className="text">賞屋預約</div>
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Page;
