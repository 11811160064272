import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import { Router } from "@reach/router";
import Link from "../LinkWithTransitionLock";
import Helmet from "react-helmet";
// import Headroom from "headroom.js";
// import InnerHeight from "ios-inner-height";
// import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power3,
  Power4,
  Expo,
  gsap,
} from "gsap";

import view_main from "../../images/view/360view-2.jpg";
import view_1 from "../../images/view/view_1.jpg";
import view_2 from "../../images/view/view_2.jpg";
import view_3 from "../../images/view/士林捷運站-DSC05178.jpg";
import view_4 from "../../images/view/view_3.jpg";
import view_5 from "../../images/view/芝山捷運站-DSC05525.jpg";
import view_6 from "../../images/view/view_4.jpg";
import view_7 from "../../images/view/view_9.jpg";
import view_8 from "../../images/view/view_6.jpg";

import window from "global";
import { func } from "prop-types";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (navigator.userAgent.match(/iPhone/)) {
      document.querySelector("body").classList.add("iphone");
    }

    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.remove("inner");
    document.querySelector("body").classList.add("overflow");

    var view = document.querySelector(".view");
    var view_wrap = document.querySelector(".view_wrap");
    var point_item = document.querySelectorAll(".view_point_list .list_item");
    var point_photo = document.querySelector(".point_photo");
    var point_photo_inner = document.querySelector(".point_photo_inner");
    var view_img = document.querySelector(".view_img");
    var point_name = document.querySelector(".point_name");
    var dragItem = document.querySelector(".view_point_list");
    var box1 = document.querySelector(".view");
    // var box2 = document.querySelector("#box2");
    var container = dragItem;
    //Declare Variables
    var active = false;
    var currentX;
    var currentY;
    var initialX;
    var initialY;
    var xOffset = -290;
    var yOffset = 0;

    box1.style.width = window.innerHeight * 3 + "px";
    box1.style.height = window.innerHeight + "px";

    gsap.to(".view_img", {
      transformStyle: "preserve-3d",
      perspective: "0px",
      rotateX: "0",
      scaleY: 1,
      scale: 1,
      duration: 5,
      ease: Power3.easeOut,
      immediateRender: false,
    });

    TweenMax.staggerTo(
      ".view_point_list .list_item",
      1.2,
      {
        opacity: 1,
        y: "0%",
        ease: Power4.easeOut,
        delay: 1,
      },
      0.2
    );

    point_item.forEach(function(item) {
      item.addEventListener("mouseover", function() {
        view.classList.add("active");
        point_photo_inner.style.backgroundImage =
          "url(" + item.dataset.photo + ")";
        point_name.textContent = item.dataset.name;
        point_photo.classList.add("active_in");

        point_photo.classList.remove("active_out");
      });
      item.addEventListener("mouseleave", function() {
        view.classList.remove("active");
        point_photo.classList.remove("active_in");
        point_photo.classList.add("active_out");
        setTimeout(() => {
          point_photo.classList.remove("active_out");
        }, 400);
        setTimeout(() => {}, 1000);
      });

      item.addEventListener("touchstart", function() {
        view.classList.add("active");
      });
      item.addEventListener("touchend", function() {
        view.classList.remove("active");
      });
    });

    // view_point_list.style.width = dragItem.offsetWidth + 'px';
    // view_point_list.style.height = window.outerHeight + 'px';

    //Add Event Listeners for Touchscreens
    container.addEventListener("touchstart", dragStart, false);
    container.addEventListener("touchend", dragEnd, false);
    container.addEventListener("touchmove", drag, false);

    //Add Event Listeners for Mice
    container.addEventListener("mousedown", dragStart, false);
    container.addEventListener("mouseup", dragEnd, false);
    container.addEventListener("mousemove", drag, false);

    function dragStart(e) {
      //when the drag starts
      if (e.type === "touchstart") {
        //if its a touchscreen
        initialX = e.touches[0].clientX - xOffset; //set initial x-cordinate to where it was before drag started
        // initialY = e.touches[0].clientY - yOffset; //set initial y-cordinate to where it was before drag started
      } else {
        //if its not a touchscreen (mouse)
        initialX = e.clientX - xOffset; //set initial x-cordinate to where it was before drag started
        // initialY = e.clientY - yOffset; //set initial y-cordinate to where it was before drag started
      }
      view_wrap.classList.add("grabbing");

      if (e.target === dragItem) {
        //if user is dragging circle
        active = true; //the drag is active
      }
    }

    function dragEnd(e) {
      //when the drag ends
      const box1Size = box1.getBoundingClientRect(); //the size of box1
      // const box2Size = box2.getBoundingClientRect(); //the size of box2
      const elementSize = dragItem.getBoundingClientRect(); //the size of the circle

      view_wrap.classList.remove("grabbing");

      // if (elementSize.left > 0) {
      //   console.log("out1");
      //   // initialX = 0;
      // } else if (-window.innerWidth - elementSize.left < 0) {
      //   console.log("out2");
      //   // initialX = window.innerWidth - elementSize.left;
      // }

      const right_end_x =
        window.outerWidth -
        document.querySelector(".view_wrap").getBoundingClientRect().width;

      if (currentX <= right_end_x) {
        // currentX = currentX;
        // currentY = 0;
        initialX = right_end_x;
        // initialY = 0;
        xOffset = right_end_x;
        // yOffset = 0;
        setTranslate(right_end_x, 0, dragItem);
      }

      if (
        elementSize.left >= box1Size.left &&
        elementSize.right <= box1Size.right &&
        elementSize.top <= box1Size.top &&
        elementSize.bottom >= box1Size.bottom
      ) {
        //if the circle is in box1
        // initialX = currentX; //set the initial x-cordinate to where it is now
        // initialY = currentY; //set the initial y-cordinate to where it is now
        // console.log("out");

        currentX = 0;
        // currentY = 0;
        initialX = 0;
        // initialY = 0;
        xOffset = 0;
        // yOffset = 0;
        setTranslate(0, 0, dragItem);
      } else {
        //if the circle is in neither box1 nor box2
        // currentX = 0;
        // currentY = 0;
        // initialX = 0;
        // initialY = 0;
        // xOffset = 0;
        // yOffset = 0;
        // setTranslate(0, 0, dragItem);
        // console.log("end");
      }

      active = false; //the drag is no longer active
    }

    function drag(e) {
      //when the circle is being dragged
      if (active) {
        //if the drag is active
        e.preventDefault(); //the user cant do anything else but drag

        if (e.type === "touchmove") {
          //if its a touchscreen
          currentX = e.touches[0].clientX - initialX; //set current x-cordinate to where it is now
          // currentY = e.touches[0].clientY - initialY; //set current y-cordinate to where it is now
        } else {
          //if its not a touchscreen (mouse)
          currentX = e.clientX - initialX; //set current x-cordinate to where it is now
          // currentY = e.clientY - initialY; //set current y-cordinate to where it is now
        }

        //Im not sure what this does but it dosnt work without it
        xOffset = currentX;
        // yOffset = currentY;
        setTranslate(currentX, 0, dragItem);
        // console.log(currentX);
      }
    }

    var view_wrap = document.querySelector(".view_wrap");
    function setTranslate(xPos, yPos, el) {
      //Im not sure what this does but it dosnt work without it
      // el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";

      const elementSize = dragItem.getBoundingClientRect();
      var right_end_x =
        window.outerWidth -
        document.querySelector(".view_wrap").getBoundingClientRect().width;

      if (xPos <= right_end_x) {
        view_wrap.style.transform = "translateX(" + right_end_x + ")";
      } else {
        if (xPos >= 0) {
          view_wrap.style.transform = "translateX(0px)";
          // view_point_list.style.transform = "translateX(0px)";
        } else {
          // view_wrap.style.transform = "translateX(" + xPos + "px)";
          gsap.to(".view_wrap", {
            x: xPos,
            duration: 0.3,
            ease: Power1.easeOut,
          });

          view.classList.remove("active");
          point_photo.classList.remove("active");
          // view_point_list.style.transform = "translateX(" + xPos + "px)";
        }
      }

      // var right_end_x =
      //   window.outerWidth -
      //   document.querySelector(".view_wrap").getBoundingClientRect().width;
      // if (
      //   window.outerWidth -
      //     document.querySelector(".view_wrap").getBoundingClientRect().width -
      //     document.querySelector(".view_img").getBoundingClientRect().left >=
      //   0
      // ) {
      //   // view_wrap.style.transform = "translateX(" + right_end_x + "px)";
      //   // view_point_list.style.transform = "translateX(" + right_end_x + "px)";
      //   // console.log('end');
      //   gsap.to(".view_wrap", {
      //     x: right_end_x,
      //     duration: 0.3,
      //     ease: Power1.easeOut,
      //   });
      // } else {
      //   // console.log("end");
      //   // document.querySelector(".view_wrap").style.transform = "translateX(" + right_end_x + "px)";
      // }
    }

    // document.querySelector(".view_img").addEventListener("onload", function() {
    //   console.log(document.querySelector(".view_img").offsetWidth);
    // });
    var info_box = document.querySelector(".info_box");
    var back_to = document.querySelector(".back_to");
    var url_string = window.location.href;
    var url = new URL(url_string);
    var floor = url.searchParams.get("fl");
    if (floor != null) {
      // console.log(floor);
      info_box.classList.add("hide");
      back_to.classList.remove("hide");
      back_to.href = "../floor/" + floor;
      // console.log(back_to.href);
    }

    gsap.to(".info_box", {
      x: 0,
      opacity: 0.8,
      duration: 1,
      delay: 2,
      ease: Power4.easeOut,
      immediateRender: false,
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="view">
        <Link className="info_box" to="../location">
          <h6 className="sub_title">Lifestyle</h6>
          <h3 className="title">生活體驗</h3>
          <p className="intro">
            想在附近走走，最受當地人喜愛的雙溪河濱公園，或碧草如茵的福林公園都很適合，還可以漫步至士林官邸欣賞四季花展。
          </p>
          <div className="inner_link">
            <div className="link_text">
              知山生活地圖
              <i>→</i>
            </div>
          </div>
        </Link>
        <a className="info_box back_to hide" href="../location">
          <div className="inner_link">
            <div className="link_text">
              <i>←</i>
              回樓層平面
            </div>
          </div>
        </a>
        <div className="view_wrap">
          <ul className="view_point_list">
            <li
              className="list_item point_a"
              data-photo={view_1}
              data-name="士林官邸"
            >
              <h6>士林官邸</h6>
            </li>
            <li
              className="list_item point_b"
              data-photo={view_2}
              data-name="福林國小"
            >
              <h6>福林國小</h6>
            </li>
            <li
              className="list_item point_g"
              data-photo={view_3}
              data-name="捷運士林站"
            >
              <h6>捷運士林站</h6>
            </li>
            <li
              className="list_item point_c"
              data-photo={view_4}
              data-name="雙溪河濱公園"
            >
              <h6>雙溪河濱公園</h6>
            </li>
            <li
              className="list_item point_h"
              data-photo={view_5}
              data-name="捷運芝山站"
            >
              <h6>捷運芝山站</h6>
            </li>
            <li
              className="list_item point_d"
              data-photo={view_6}
              data-name="天母SOGO"
            >
              <h6>天母SOGO</h6>
            </li>
            <li
              className="list_item point_e"
              data-photo={view_7}
              data-name="陽明山"
            >
              <h6>陽明山</h6>
            </li>
            <li
              className="list_item point_f"
              data-photo={view_8}
              data-name="芝山岩"
            >
              <h6>芝山岩</h6>
            </li>
          </ul>
          <img
            src={view_main}
            className="view_img"
            alt="生活風景・璞園知山・知己然後知山・璞園建築團隊"
          />
        </div>
        <div className="point_photo">
          <div className="point_photo_inner">
            <div className="point_name">士林官邸</div>
          </div>
        </div>

        <Helmet
          title="生活體驗・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "生活體驗・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            // {
            //   property: `og:image`,
            //   // content:
            //   // "https://online.home-to-all.com" +
            //   // require("../images/422socialimage.jpg"),
            // },
          ]}
        ></Helmet>
      </div>
    );
  }
}

export default Page;
