import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import Link from "./LinkWithTransitionLock";
import { isMobile } from "mobile-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power3,
  Power4,
  Expo,
  gsap,
} from "gsap";
import SelectBuilding from "./SelectBuilding";
import View from "./View";
import g from "gsap-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import VideoIsPlayedContext from "./context";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  static contextType = VideoIsPlayedContext;

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    function installMediaQueryWatcher(mediaQuery, layoutChangedCallback) {
      var mql = window.matchMedia(mediaQuery);
      mql.addListener(function(e) {
        return layoutChangedCallback(e.matches);
      });
      layoutChangedCallback(mql.matches);
    }

    document.querySelector("body").classList.add("overflow");

    var window_width = window.innerWidth;
    var window_height = window.innerHeight;
    var window_height_mobile = window.outerHeight;

    var hero = document.querySelector(".hero");
    var hero_cover = document.querySelector(".hero_cover");
    var hero_logo = document.querySelector(".hero_logo");
    var hero_video = document.querySelector(".hero_video");
    var hero_skip = document.querySelector(".hero_skip");

    var header = document.querySelector(".header");
    var sky = document.querySelector(".sky");
    var building = document.querySelector(".building");
    var visual = document.querySelector(".visual");
    var featured_border = document.querySelector(".featured_border");
    var featured = document.querySelector(".featured");
    var featured_list = document.querySelector(".featured_list");
    var section_bg = document.querySelector(".section_bg");
    var select_floor = document.querySelector(".select_floor");
    var view = document.querySelector(".view");
    var cloud_animation = document.querySelector(".cloud_animation");
    cloud_animation.style.height = window_height * 2 + "px";

    if (this.context.indexVideoIsPlayed) hero_video.currentTime = 8;
    this.context.setIndexVideoIsPlayed(true);

    header.classList.remove("inner");
    header.classList.add("hide");

    hero_cover.style.height = window_height * 1.1 + "px";
    hero_logo.style.width = window_width + "px";
    hero_logo.style.height = window_height + "px";
    hero.style.marginTop = -window_height_mobile + "px";

    var hero_logo_tl = new TimelineMax();
    hero_logo_tl
      .to(".hero_logo", {
        opacity: 1,
        duration: 1.5,
        ease: Power2.easeOut,
      })
      .to(".hero_logo", {
        opacity: 0,
        duration: 1.5,
        delay: 0.5,
        ease: Power1.easeOut,
      });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      // hero_video.play();
      var promise = hero_video.play();
      if (promise !== undefined) {
        promise
          .catch((error) => {
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
          })
          .then(() => {
            // Auto-play started
            // alert("a");
          });
      }
      hero_video.classList.add("play");
      gsap.to(".hero_skip", {
        opacity: 1,
        duration: 0.4,
        delay: 0.5,
        ease: Power1.easeOut,
      });
    }, 2500);

    hero_skip.addEventListener("click", () => {
      hero_video.currentTime = 8;
    });

    hero_video.onended = (event) => {
      document.querySelector("body").classList.remove("overflow");
      cloud_animation.play();

      hero_cover.classList.add("out");

      gsap.to(".hero_cover", {
        opacity: 0,
        duration: 1,
        ease: Power4.easeInOut,
      });
      gsap.to(".hero", {
        opacity: 1,
        duration: 4,
        ease: Power3.easeInOut,
      });

      gsap.to(".cloud_animation", {
        opacity: 1,
        duration: 2,
        // duration: 1,
        // delay: 2,
        ease: Power2.easeInOut,
      });
      installMediaQueryWatcher("(min-width: 767px)", function(matches) {
        if (matches) {
          gsap.to(".building", {
            opacity: 1,
            top: "0vh",
            duration: 3,
            // duration: 2,
            delay: 0.6,
            ease: Power2.easeInOut,
          });
        } else {
          gsap.to(".building", {
            opacity: 1,
            top: "0",
            duration: 2,
            // duration: 2,
            delay: 0.6,
            ease: Power2.easeInOut,
          });
        }
      });
      gsap.to(".slogan", {
        bottom: "0%",
        opacity: 1,
        // duration: 2,
        duration: 2,
        delay: 1.2,
        ease: Power2.easeInOut,
      });
      gsap.to(".scroll_hint", {
        bottom: "0%",
        opacity: 0.5,
        // duration: 2,
        duration: 2,
        delay: 1.4,
        ease: Power2.easeInOut,
      });

      gsap.to(".header", {
        css: {
          className: "-=header",
        },
        delay: 1,
        ease: Power3.easeOut,
      });

      // setTimeout(() => {
      // }, 500);
    };

    var featured_box = document.querySelectorAll(".featured_box");
    var photo_scroll_bg_position = -window_height * 0.5 + "px";
    var photo_scroll_height = window_height * 1 + "px";
    var item_scroll = window_height * 0.5 + "px";

    setTimeout(() => {
      gsap.to(".building", {
        scrollTrigger: {
          trigger: ".section_container",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          // markers: true,
        },
        y: "20%",
        // scale: 1.1
      });

      // gsap.to(".building", {
      //   scrollTrigger: {
      //     trigger: ".item_a",
      //     start: "top bottom",
      //     end: "bottom top",
      //     scrub: true,
      //     pin: true,
      //     anticipatePin: 1,
      //     markers: true,
      //   },
      // });
      gsap.to(".slogan .text_b", {
        scrollTrigger: {
          trigger: ".hero",
          start: "center bottom",
          end: "bottom top",
          scrub: true,
          // markers: true,
        },
        width: "125%",
      });
      gsap.to(".cover", {
        scrollTrigger: {
          trigger: ".item_a",
          start: "top bottom",
          end: "center top",
          scrub: true,
          // markers: true,
        },
        opacity: 1,
      });
      gsap.to(".select_floor", {
        scrollTrigger: {
          trigger: ".item_d",
          start: "top",
          end: "+=" + window.innerHeight,
          scrub: true,
          // markers: true,
        },
        display: "block",
      });
      gsap.to(".select_floor .building_photo", {
        scrollTrigger: {
          trigger: ".item_e",
          start: "top",
          end: "+=" + window.innerHeight,
          scrub: true,
          // markers: true,
        },
        opacity: 1,
      });

      installMediaQueryWatcher("(min-width: 767px)", function(matches) {
        if (matches) {
          // let featured_list_item_height = -window.innerHeight * 0.7;

          gsap.utils
            .toArray(".featured_list .list_item")
            .forEach((section, i) => {
              section.bg = section.querySelector(".photo");
              // section.text = section.querySelector(".text");
              if (i) {
                section.bg.style.transform =
                  "translateY(" + `${-window.innerHeight * 0.5}px` + ")";
                gsap.to(section.bg, {
                  y: `${window.innerHeight * 0.3}px`,
                  ease: "none",
                  scrollTrigger: {
                    trigger: section,
                    scrub: true,
                    // markers: true,
                  },
                });
              } else {
                // section.bg.style.y = "0px";
                section.bg.style.transform =
                  "translateY(" + `${-window.innerHeight * 0.5}px` + ")";
                gsap.to(section.bg, {
                  y: `${window.innerHeight * 0.3}px`,
                  ease: "none",
                  scrollTrigger: {
                    trigger: section,
                    // start: "top top",
                    // end: "bottom top",
                    scrub: true,
                  },
                });
              }
            });
        } else {
          // let featured_list_item_height = -window.outerWidth;
          gsap.utils
            .toArray(".featured_list .list_item")
            .forEach((section, i) => {
              section.bg = section.querySelector(".photo");
              // section.text = section.querySelector(".text");
              if (i) {
                section.bg.style.transform =
                  "translateY(" + `${-window.innerHeight * 1}px` + ")";
                gsap.to(section.bg, {
                  y: `${window.innerHeight * 0.3}px`,
                  ease: Linear.easeOut,
                  scrollTrigger: {
                    trigger: section,
                    scrub: true,
                    // markers: true,
                  },
                });
              } else {
                // section.bg.style.y = "0px";
                section.bg.style.transform =
                  "translateY(" + `${-window.innerHeight * 1}px` + ")";
                gsap.to(section.bg, {
                  y: `${window.innerHeight * 0.3}px`,
                  ease: Linear.easeOut,
                  scrollTrigger: {
                    trigger: section,
                    // start: "top top",
                    // end: "bottom top",
                    scrub: true,
                  },
                });
              }
            });
        }
      });

      // gsap.to(".view_container .view", {
      //   scrollTrigger: {
      //     trigger: ".view",
      //     start: "+=" + window.innerHeight * 0.25,
      //     end: "+=" + window.innerHeight,
      //     scrub: true,
      //     // markers: true,
      //   },
      //   opacity: 0.3,
      //   // y: "20%",
      // });
      // gsap.to(".view_container .view", {
      //   scrollTrigger: {
      //     trigger: ".view",
      //     start: "top",
      //     end: "+=" + window.innerHeight * 1.5,
      //     scrub: true,
      //     // markers: true,
      //   },
      //   y: "50%",
      // });
    }, 1000);

    var featured_item_link = document.querySelectorAll(
      ".featured_box .item_link"
    );
    featured_item_link.forEach(function(element) {
      element.addEventListener("mouseover", function(e) {
        element.classList.remove("out");
        element.classList.add("in");
      });
      element.addEventListener("mouseout", function(e) {
        element.classList.remove("in");
        element.classList.add("out");
      });
    });

    // featured_border.style.top = featured.getBoundingClientRect().y + "px";
    var featured_border_top = featured.getBoundingClientRect().y + "px";
    featured_border.style.transform = "translatex(" + featured_border_top + ")";
    window.addEventListener("scroll", function() {
      // var heroScrollTop =
      //   hero.getBoundingClientRect().y + hero.getBoundingClientRect().height;
      // if (heroScrollTop < "0") {
      //   header.classList.add("inner");
      // } else {
      //   header.classList.remove("inner");
      // }

      // featured_border.style.top = featured.getBoundingClientRect().y + "px";
      featured_border.style.transform =
        "translatex(" + featured_border_top + ")";

      var featured_list = document.querySelectorAll(
        ".featured_list .list_item"
      );
      featured_list.forEach(function(element) {
        if (element.getBoundingClientRect().y - window_height * 0.8 < 0) {
          element.classList.add("enter");
        } else {
          element.classList.remove("enter");
        }
      });

      var section = document.querySelectorAll("section");
      section.forEach(function(element) {
        if (element.getBoundingClientRect().y - window_height * 0.8 < 0) {
          element.classList.add("enter");
        } else {
          element.classList.remove("enter");
        }
      });

      if (featured.getBoundingClientRect().y - window.innerHeight) {
      }

      // if (
      //   featured.getBoundingClientRect().y < 0 &&
      //   featured.offsetHeight +
      //     featured.getBoundingClientRect().y -
      //     window_height * 1.2 >
      //     0
      // ) {
      //   section_bg.classList.add("enter");
      // } else {
      //   section_bg.classList.remove("enter");
      // }

      // if (hero.getBoundingClientRect().y < -hero.offsetHeight) {
      //   select_floor.classList.add("available");
      //   cloud_animation.pause();
      // } else {
      //   select_floor.classList.remove("available");
      //   // cloud_animation.play();
      // }
    });

    window.addEventListener("resize", function() {
      var window_width = window.innerWidth;
      var window_height = window.innerHeight;
      var cloud_animation = document.querySelector(".cloud_animation");
      if (cloud_animation != null) {
        cloud_animation.style.height = window_height + "px";
        if (cloud_animation.clientHeight <= window_height) {
          cloud_animation.style.height = window_height * 2 + "px";
        }
      }
      // cloud_animation.style.width = window_width + "px";

      installMediaQueryWatcher("(min-width: 767px)", function(matches) {
        if (matches) {
          featured_box.forEach(function(element) {
            element.style.height = window.innerHeight * 0.7 + "px";
          });
        } else {
          featured_box.forEach(function(element) {
            element.style.height = window.outerWidth + "px";
          });
        }
      });
    });

    installMediaQueryWatcher("(min-width: 767px)", function(matches) {
      if (matches) {
        // featured_list.style.height = window.innerHeight * 2.4 + "px";
        featured_box.forEach(function(element) {
          element.style.height = window.innerHeight * 0.7 + "px";
        });
      } else {
        featured_box.forEach(function(element) {
          // featured_list.style.height = window.outerHeight * 2.4 + "px";
          element.style.height = window.outerWidth + "px";
        });
      }
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <section className="hero_cover">
          <video
            className="hero_video"
            // autoPlay
            playsInline
            muted="muted"
            src={require("../videos/hero_video_3s.mp4")}
          ></video>
          <div className="hero_logo"></div>
          <div className="hero_skip">
            SKIP <i className="fa fa-angle-right"></i>
          </div>
        </section>
        <section className="hero full_width">
          <h2 className="slogan">
            <div className="text_wrap">
              <div className="text text_a">
                知己
                <br />
                然後知山
              </div>
              <div className="text text_b">
                知己
                <br />
                然後知山
              </div>
            </div>
          </h2>
          <div className="scroll_hint"></div>
          <div className="visual">
            <div className="cover"></div>
            <div className="building"></div>
            {/* <div className="cloud"></div> */}
            <video
              className="cloud_animation"
              loop
              // autoPlay
              playsInline
              muted="muted"
              src={require("../videos/hero_cloud_6s.mp4")}
            ></video>
          </div>
          <div className="video_play_btn">
            <i className="fa fa-play"></i>
          </div>
        </section>
        <div className="featured_border"></div>

        <section className="section_container dark">
          <div className="inner_content home_content">
            <article className="row_area_ms">
              <div className="col_1 main_title">
                <h3>
                  山腳下找回
                  <br />
                  隱於市的快樂
                </h3>
                {/* <p></p> */}
              </div>
              <div className="col_1 context">
                <p>
                  你理想的生活是什麼樣子？走過大風大浪，真心希望的不過是一處寧靜自在的好居所。不用再與外界妥協，不用勉強和誰交陪。更多時間留給大自然，不論沿河漫步，或走向群山，原來在熙攘的台北市，也能這樣生活。
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="featured">
          {/* <div className="section_bg">
            <div className="text text_a"></div>
            <div className="text text_b"></div>
          </div> */}
          <ul className="featured_list">
            <li className="list_item item_a">
              <Link to="/archi/" className="featured_box">
                <div className="text">
                  <div className="top">
                    <h3>一層一戶</h3>
                  </div>
                  <div className="bottom t_left">
                    <h4>獨享廣袤天地</h4>
                  </div>
                  <div className="item_link">
                    <div className="label">了解更多</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div
                  className="photo left"
                  style={{
                    backgroundImage: `url(${require("../images/home_item_a.jpg")})`,
                  }}
                ></div>
              </Link>
            </li>
            <li className="list_item item_b">
              <Link className="featured_box" to="/planeasyfloor/">
                <div className="text">
                  <div className="top">
                    <h3>自由平面</h3>
                  </div>
                  <div className="bottom">
                    <h4>全齡彈性空間</h4>
                  </div>
                  <div className="item_link">
                    <div className="label">了解更多</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div className="photo">
                  <video
                    className="video"
                    autoPlay
                    loop
                    playsInline
                    muted="muted"
                    src={require("../videos/home_item_b_2s.mp4")}
                  ></video>
                </div>
              </Link>
            </li>
            <li className="list_item item_c">
              <Link className="featured_box" to="/archi/#lighting_video">
                <div className="text">
                  <div className="top">
                    <h3>謐光環境</h3>
                  </div>
                  <div className="bottom t_left">
                    <h4>匠心獨具作品</h4>
                  </div>
                  <div className="item_link">
                    <div className="label">了解更多</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div
                  className="photo left"
                  style={{
                    backgroundImage: `url(${require("../images/home_item_c.jpg")})`,
                  }}
                ></div>
              </Link>
            </li>
            <li className="list_item item_d">
              <a
                className="featured_box"
                href="https://www.pyct.com.tw"
                target="_blank"
              >
                <div className="text">
                  <div className="top">
                    <h3>精工細作</h3>
                  </div>
                  <div className="bottom">
                    <h4>璞園建築團隊</h4>
                  </div>
                  <div className="item_link out_link">
                    <div className="label">了解更多</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div
                  className="photo"
                  style={{
                    backgroundImage: `url(${require("../images/home_item_d.jpg")})`,
                  }}
                ></div>
                {/* <div className="item_link" target="_blank">
                    ↗
                  </div> */}
              </a>
            </li>
            <li className="list_item item_e">
              <Link className="featured_box" to="../view/">
                <div className="text">
                  <div className="top">
                    <h3>生活地圖</h3>
                  </div>
                  <div className="bottom">
                    <h4>漫步知山生活</h4>
                  </div>
                  <div className="item_link">
                    <div className="label">立即體驗</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div
                  className="photo"
                  style={{
                    backgroundImage: `url(${require("../images/home_item_e.jpg")})`,
                  }}
                ></div>
              </Link>
            </li>
            {/* <li className="list_item item_e">
              <Link className="featured_box" to="../select/">
                <div className="text">
                  <div className="top">
                    <h3>建築平面</h3>
                  </div>
                  <div className="bottom">
                    <h4>璞園建築團隊</h4>
                  </div>
                  <div className="item_link out_link">
                    <div className="label">選擇知山</div>
                    <i className="label_icon">→</i>
                  </div>
                </div>
                <div
                  className="photo"
                  style={{
                    backgroundImage: `url(${require("../images/building_all_2.jpg")})`,
                  }}
                ></div>
              </Link>
            </li> */}
          </ul>

          {/* <div className="view_container">
            <View />
          </div> */}
        </section>

        <SelectBuilding />

        {/* <footer className="footer">
          <div className="upper">
            <div className="nav_wrap">
              <ul className="page_nav_list">
                <li className="list_item">
                  <a href="" className="label">
                    建築特色
                  </a>
                </li>
                <li className="list_item">
                  <a href="" className="label">
                    建築特色
                  </a>
                </li>
                <li className="list_item">
                  <a href="" className="label">
                    建築特色
                  </a>
                </li>
                <li className="list_item">
                  <a href="" className="label">
                    建築特色
                  </a>
                </li>
              </ul>
              <ul className="social_nav">
                <li className="list_item">
                  <i className="fa fa-facebook"></i>
                </li>
                <li className="list_item">
                  <i className="fa fa-instagram"></i>
                </li>
              </ul>
            </div>
            <div className="pyct_info">
              <div className="pyct_logo">
                <a href=""></a>
              </div>
              <div className="quote">
                <p>誠信、品質，一直是我們的核心價值</p>
              </div>
            </div>
          </div>
        </footer> */}

        <Helmet
          title="璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        >
          {/* <script src="//typesquare.com/3/tsst/script/zh_tw/typesquare.js?60a781ebc9b44558b3fe144aac1e02e5" /> */}
        </Helmet>
      </>
    );
  }
}

export default Page;
