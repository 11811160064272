import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Link from "./LinkWithTransitionLock";

import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <>
        <h1>page</h1>
        <Link to="/">home</Link>
      </>
    );
  }
}

export default Page;
