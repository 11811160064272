import React, {
  Suspense,
  lazy,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { Router, Link } from "@reach/router";
import Helmet from "react-helmet";
import Link_to from "../LinkWithTransitionLock";
import InnerHeight from "ios-inner-height";

import { Slide, Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import { isMobile } from "react-device-detect";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import window from "global";

import { navigate } from "@reach/router";
import data from "./../../data/floors";

const floorsKey = Object.keys(data).reverse();
const defaultFloorKey = "F1";

const Floor = (props) => {
  const [activeFloor, setActiveFloor] = useState(
    props.floorKey && floorsKey.indexOf(props.floorKey) !== -1
      ? props.floorKey
      : defaultFloorKey
  );
  const [activePlanIndex, setActivePlanIndex] = useState(
    Math.ceil(data[activeFloor].plans.length / 2) - 1
  );
  const [isWheelTriggering, setIsWheelTriggering] = useState(false);
  const [direction, setDirection] = useState(1);

  // const [items, set] = useState(dataForAnimation);
  // const [index, set] = useState(0);
  // const dataForAnimation = Object.keys(JSON.parse(JSON.stringify(data))).map((key, i) => (
  //   style => (
  //     <animated.div style={{ ...style }}>
  //       <div className="content_image">
  //         <ul className="image_list">
  //           <li className={`list_item`}>
  //             <img
  //               src={data[key].plans[activePlanIndex]}
  //               alt=""
  //               className="floor_img"
  //             />
  //           </li>
  //         </ul>
  //       </div>
  //     </animated.div>
  //   )
  // ));
  // console.log(data);
  // console.log(dataForAnimation)

  // const transitions = useTransition(floorsKey.indexOf(activeFloor), item => item, {
  //   from: { transform: 'translate3d(0,-40px,0)' },
  //   enter: { transform: 'translate3d(0,0px,0)' },
  //   leave: { transform: 'translate3d(0,-40px,0)' },
  // });
  // console.log(transitions)

  useEffect(() => {
    var floor_note = document.querySelector(".floor_note");
    var floor_note_switch = document.querySelector(".floor_note .switch");
    floor_note_switch.addEventListener("click", function() {
      floor_note.classList.toggle("active");
    });

    var floor_panel_container = document.querySelector(
      ".floor_panel_container"
    );
    var floor_content = document.querySelector(".floor_content");
    var floor_height = document.querySelector(".floor_height");
    var height_grid = document.querySelector(".height_grid");
    var floor_nav_wrap = document.querySelector(".floor_nav_wrap");
    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.add("inner");
    document.querySelector("body").classList.remove("overflow");

    if (navigator.userAgent.match(/iPhone/)) {
      document.querySelector("body").classList.add("iphone");
      // if (window.innerWidth < 960) {
      //   // floor_content.style.height = floor_height.offsetTop + "px";
      //   floor_content.style.bottom =
      //     window.innerHeight - floor_nav_wrap.offsetTop + "px";
      // }
    }

    floor_panel_container.style.height = window.innerHeight + "px";

    window.addEventListener("resize", () => {
      floor_panel_container.style.height = window.innerHeight + "px";
    });
  }, []);

  const switchFloor = (e, offsetNum) => {
    e.preventDefault();
    const nextFloorIndex = floorsKey.indexOf(activeFloor) + offsetNum;
    if (nextFloorIndex < floorsKey.length && nextFloorIndex >= 0) {
      setDirection(offsetNum > 0 ? 1 : -1);
      setActiveFloor(floorsKey[nextFloorIndex]);
      setActivePlanIndex(
        Math.ceil(data[floorsKey[nextFloorIndex]].plans.length / 2) - 1
      );

      // window.location.replace(`/floor/${floorsKey[nextFloorIndex]}`);
      // navigate(`/floor/${floorsKey[nextFloorIndex]}`);

      const nextURL = `/floor/${floorsKey[nextFloorIndex]}`;
      // const nextTitle = "My new page title";
      // const nextState = { additionalInformation: "Updated the URL with JS" };

      // This will create a new entry in the browser's history, without reloading
      window.history.pushState({}, document.title, nextURL);
    }
  };

  const properties = {
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    canSwipe: true,
  };

  return (
    <div className="floor_panel_container">
      <>
        <Helmet
          title="建築平面・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "建築平面・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            // {
            //   property: `og:image`,
            //   // content:
            //   // "https://online.home-to-all.com" +
            //   // require("../images/422socialimage.jpg"),
            // },
          ]}
        ></Helmet>
      </>
      <div className="floor_nav_wrap">
        <ul
          className="nav_list"
          style={{
            transform: `translateX(${50 -
              (100 / (floorsKey.length + 2)) *
                (floorsKey.indexOf(activeFloor) + 1.5)}%)`,
          }}
        >
          {floorsKey.map((floorKey, index) => (
            <li
              key={index}
              className={`list_item ${
                activeFloor === floorKey ? "current" : ""
              }`}
            >
              <span className="item_label">
                {data[floorKey].displayedShortName}
              </span>
            </li>
          ))}
        </ul>
        <div className="floor_nav">
          <div className="nav_btn go_up" onClick={(e) => switchFloor(e, -1)}>
            <i className="fa fa-angle-up"></i>
          </div>
          <div className="on_the_floor"></div>
          <div className="nav_btn go_down" onClick={(e) => switchFloor(e, 1)}>
            <i className="fa fa-angle-down"></i>
          </div>
        </div>
      </div>

      <div className="floor_height">
        <div className="height_list_wrap">
          <ul className="height_list">
            {/* {floorsKey.map((floorKey, index) =>
              <li 
                key={index} 
                className={`list_item ${activeFloor===floorKey ? 'current' : ''}`}
              >{data[activeFloor].floorHeight}m</li>
            )} */}
            <li className="list_item up">
              {data[floorsKey[floorsKey.indexOf(activeFloor) - 1]]
                ? data[floorsKey[floorsKey.indexOf(activeFloor) - 1]]
                    .floorHeight
                : "00"}
              <i>m</i>
            </li>
            <li className="list_item current">
              {data[activeFloor].floorHeight} m
            </li>
            <li className="list_item down">
              {data[floorsKey[floorsKey.indexOf(activeFloor) + 1]]
                ? data[floorsKey[floorsKey.indexOf(activeFloor) + 1]]
                    .floorHeight
                : "00"}
              <i>m</i>
            </li>
          </ul>
        </div>
        <div className="height_grid">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>

      {/* 特定樓層才有 VR 或 全景 */}

      <div className="floor_other">
        {data[activeFloor].vrLink ? (
          <a className="floor_vr floor_icon">
            <span className="vr_icon" data-zh="虛擬體驗" data-en="VR">
              虛擬體驗
            </span>
          </a>
        ) : (
          ""
        )}

        {data[activeFloor].fullView ? (
          <Link_to
            className="floor_360_view floor_icon"
            to={data[activeFloor].fullView}
          >
            <span className="floor_360_view_icon">全景</span>
          </Link_to>
        ) : (
          ""
        )}
      </div>

      <div
        className="floor_content"
        draggable={false}
        onWheel={(e) => {
          const offsetY = e.deltaY;
          e.preventDefault();
          if (!isWheelTriggering && Math.abs(offsetY) >= 50) {
            setIsWheelTriggering(true);
            if (offsetY > 0) switchFloor(e, 1);
            if (offsetY < 0) switchFloor(e, -1);
            setTimeout(() => {
              setIsWheelTriggering(false);
            }, 700);
          }
        }}
      >
        <div className="content_option">
          {data[activeFloor].plans.length > 1 ? (
            <ul className="option_list">
              {data[activeFloor].plans.map((plan, index) => (
                <li
                  key={index}
                  className={`list_item ${
                    activePlanIndex === index ? "current" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActivePlanIndex(index);
                  }}
                >
                  <span className="item_label">
                    {/* Plan {String.fromCharCode(index + "A".charCodeAt(0))} */}
                    {data[activeFloor].plansTitle[index]}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
        <div className="content_image">
          <ul className={`image_list ${direction > 0 ? "up" : "down"}`}>
            {floorsKey.map((floorKey, index) => (
              <li
                className={`list_item ${
                  activeFloor === floorKey
                    ? "current"
                    : floorsKey.indexOf(floorKey) <
                      floorsKey.indexOf(activeFloor)
                    ? "higger"
                    : "lower"
                }`}
                key={index}
              >
                {data[floorKey].plans.map((imgUrl, jedex) =>
                  imgUrl.length > 5 ? (
                    <img
                      src={imgUrl}
                      alt={imgUrl.length}
                      className={`floor_img ${
                        activePlanIndex === jedex ? "current" : ""
                      }`}
                    />
                  ) : (
                    <div
                      className={`floor_plan_gallery floor_img ${
                        activePlanIndex === jedex ? "current" : ""
                      }`}
                    >
                      <Fade {...properties}>
                        {imgUrl.map((url) => (
                          <img src={url} className="floor_plan_gallery_item" />
                        ))}
                      </Fade>
                    </div>
                  )
                )}
              </li>
            ))}
          </ul>
        </div>
        {/* <div className="content_image">
          <ul className="image_list">
            <li className="list_item up">
              <img
                src={
                  data[floorsKey[floorsKey.indexOf(activeFloor)-1]] ? 
                    data[floorsKey[floorsKey.indexOf(activeFloor)-1]].plans[activePlanIndex] : ''
                }
                alt=""
                className="floor_img"
              />
            </li>
            <li className="list_item current">
              <img
                src={data[activeFloor].plans[activePlanIndex]}
                alt=""
                className="floor_img"
              />
            </li>
            <li className="list_item down">
              <img
                src={
                  data[floorsKey[floorsKey.indexOf(activeFloor)+1]] ? 
                    data[floorsKey[floorsKey.indexOf(activeFloor)+1]].plans[activePlanIndex] : ''
                }
                alt=""
                className="floor_img"
              />
            </li>
          </ul>
        </div> */}
        {/* {data[activeFloor].pingOfTotal &&
        data[activeFloor].pingOfBalcony &&
        data[activeFloor].publicRatio ? (
          <div className="content_detail">
            <ul className="detail_list">
              <div className="main_detail">
                <li className="detail_item">
                  <h6 className="title">總面積</h6>
                  <p className="meta">
                    {data[activeFloor].pingOfTotal}
                    <i>坪</i>
                  </p>
                </li>
              </div>
              <div className="sub_detail">
                <li className="detail_item">
                  <h6 className="title">陽台</h6>
                  <p className="meta">
                    {data[activeFloor].pingOfBalcony}
                    <i>坪</i>
                  </p>
                </li>
                <li className="detail_item">
                  <h6 className="title">公設比</h6>
                  <p className="meta">{data[activeFloor].publicRatio}%</p>
                </li>
              </div>
            </ul>
          </div>
        ) : (
          ""
        )} */}
      </div>

      <div className="floor_note">
        <div className="switch">
          <i className="fa fa-stexclamationar-circle"></i>
        </div>
        <div className="inner">
          <ol>
            <li>
              本平面圖及面積依建照圖繪製及計算，實際應依相關主管機關最終核定之圖說及登記面積為準
            </li>
            <li>本家俱配置圖僅供參考，實際依買賣合約書為準</li>
          </ol>
        </div>
      </div>

      <div className="panel_line">
        <div className="top">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="center">
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <div className="bottom">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

export default Floor;
