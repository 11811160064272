import * as ROUTE_TYPE from "../constant/route_type";
import * as ROUTE from "../constant/route";
import bikeIconColor from "./../images/map/icon_color_bike.png";
import bikeIconColorNag from "./../images/map/icon_color_bike_nag.png";
import hikingIconColor from "./../images/map/icon_color_hiking.png";
import hikingIconColorNag from "./../images/map/icon_color_hiking_nag.png";
import walkingIconColor from "./../images/map/icon_color_walking.png";
import walkingIconColorNag from "./../images/map/icon_color_walking_nag.png";

// 單車 - 雙溪、百齡自行車道 (A_1, A_2)
// 健行 - 芝山岩、婆婆橋健行步道 (B_1, B_2)
// 登山 - 劍潭山親山步道 (C_1)

const data = {
  [ROUTE_TYPE.BIKE]: {
    iconColor: bikeIconColor,
    iconColorNag: bikeIconColorNag,
    label: "單車",
    routes: {
      [ROUTE.BIKE_DOUBLE]: {
        name: "雙溪水岸生活自行車道",
      },
      [ROUTE.BIKE_BYE]: {
        name: "百齡自行車道",
      },
    }
  },
  [ROUTE_TYPE.WALKING]: {
    iconColor: walkingIconColor,
    iconColorNag: walkingIconColorNag,
    label: "健行",
    routes: {
      [ROUTE.WALKING_CHI]: {
        name: "芝山岩環狀步道",
      },
      [ROUTE.WALKING_PO]: {        
        name: "婆婆橋+鄭成功廟步道",
      },
    }
  },
  [ROUTE_TYPE.HIKING]: {
    iconColor: hikingIconColor,
    iconColorNag: hikingIconColorNag,
    label: "登山",
    routes: {
      [ROUTE.HIKING_JIAN]: {
        name: "絹絲瀑布登山步道",
      },
    }
  }
};

export default data;
