import * as TYPE from "../constant/route";

// 單車 - 雙溪、百齡自行車道 (A_1, A_2)
// 健行 - 芝山岩、婆婆橋健行步道 (B_1, B_2)
// 登山 - 劍潭山親山步道 (C_1)

var routes = {
  // Route A_1
  [TYPE.BIKE_DOUBLE]: [
    { lat: 25.098002332, lng: 121.513545159 },
    { lat: 25.098404139, lng: 121.514403204 },
    { lat: 25.098579828, lng: 121.515095363 },
    { lat: 25.09874754, lng: 121.515805874 },
    { lat: 25.09888428, lng: 121.516867451 },
    { lat: 25.098983221, lng: 121.517502102 },
    { lat: 25.099063381, lng: 121.517958066 },
    { lat: 25.099099385, lng: 121.518338819 },
    { lat: 25.099137239, lng: 121.518616089 },
    { lat: 25.099095837, lng: 121.519103094 },
    { lat: 25.099006314, lng: 121.51957435 },
    { lat: 25.098833711, lng: 121.520011401 },
    { lat: 25.098592326, lng: 121.520449898 },
    { lat: 25.098464786, lng: 121.520613499 },
    { lat: 25.098407275, lng: 121.520797754 },
    { lat: 25.098319374, lng: 121.520965139 },
    { lat: 25.098245319, lng: 121.521312919 },
    { lat: 25.098187643, lng: 121.521744699 },
    { lat: 25.098212424, lng: 121.522216473 },
    { lat: 25.098317652, lng: 121.522617536 },
    { lat: 25.098377799, lng: 121.522791339 },
    { lat: 25.098472763, lng: 121.522944254 },
    { lat: 25.098555368, lng: 121.523167661 },
    { lat: 25.098641714, lng: 121.52338106 },
    { lat: 25.098746569, lng: 121.523533087 },
    { lat: 25.098886579, lng: 121.523764932 },
    { lat: 25.098979137, lng: 121.52397625 },
    { lat: 25.099122762, lng: 121.524164276 },
    { lat: 25.099289104, lng: 121.524350197 },
    { lat: 25.099418366, lng: 121.524499737 },
    { lat: 25.099520928, lng: 121.524690235 },
    { lat: 25.099690264, lng: 121.524979833 },
    { lat: 25.099814665, lng: 121.525255057 },
    { lat: 25.099865127, lng: 121.525468592 },
    { lat: 25.099882482, lng: 121.525785825 },
    { lat: 25.099872231, lng: 121.526087288 },
    { lat: 25.099865296, lng: 121.526366216 },
    { lat: 25.099858401, lng: 121.526484507 },
    { lat: 25.099792788, lng: 121.526571869 },
    { lat: 25.099799691, lng: 121.526686473 },
    { lat: 25.099837742, lng: 121.527056728 },
    { lat: 25.099885148, lng: 121.527346985 },
    { lat: 25.099926571, lng: 121.52753922 },
    { lat: 25.100013631, lng: 121.527719365 },
    { lat: 25.100048187, lng: 121.52782317 },
    { lat: 25.10004674, lng: 121.527969214 },
    { lat: 25.099969724, lng: 121.528326155 },
    { lat: 25.099865638, lng: 121.528685981 },
    { lat: 25.099756968, lng: 121.528890591 },
    { lat: 25.099647908, lng: 121.529174675 },
    { lat: 25.099593316, lng: 121.529494937 },
    { lat: 25.099493519, lng: 121.529820529 },
    { lat: 25.09947551, lng: 121.530166498 },
    { lat: 25.099489273, lng: 121.530437092 },
    { lat: 25.099557297, lng: 121.53068229 },
    { lat: 25.099679587, lng: 121.530912359 },
    { lat: 25.0998426, lng: 121.531157312 },
    { lat: 25.099996993, lng: 121.531443419 },
    { lat: 25.1001602, lng: 121.531808917 },
    { lat: 25.100373094, lng: 121.532269176 },
    { lat: 25.100404774, lng: 121.532469078 },
    { lat: 25.100418352, lng: 121.532624003 },
    { lat: 25.1004093, lng: 121.532933851 },
    { lat: 25.100459047, lng: 121.533283857 },
    { lat: 25.100477126, lng: 121.533619149 },
    { lat: 25.10049526, lng: 121.533948839 },
    { lat: 25.100481829, lng: 121.534454997 },
    { lat: 25.10047984, lng: 121.534893977 },
    { lat: 25.100434529, lng: 121.535390614 },
    { lat: 25.100359404, lng: 121.535834806 },
    { lat: 25.100214018, lng: 121.536084686 },
    { lat: 25.099976956, lng: 121.536406946 },
    { lat: 25.100000786, lng: 121.53667686 },
    { lat: 25.100137903, lng: 121.536866349 },
    { lat: 25.100301183, lng: 121.536952739 },
    { lat: 25.100459724, lng: 121.536977215 },
    { lat: 25.100644122, lng: 121.53691213 },
    { lat: 25.100864593, lng: 121.536584277 },
    { lat: 25.100982023, lng: 121.536350124 },
    { lat: 25.101066113, lng: 121.536080695 },
    { lat: 25.101124095, lng: 121.535840231 },
    { lat: 25.101183207, lng: 121.535533974 },
    { lat: 25.101205042, lng: 121.535290321 },
    { lat: 25.101211654, lng: 121.534974851 },
    { lat: 25.101200874, lng: 121.534774222 },
    { lat: 25.101181323, lng: 121.53447571 },
    { lat: 25.101145094, lng: 121.534186421 },
    { lat: 25.101131244, lng: 121.5341373 },
    { lat: 25.101079709, lng: 121.53381109 },
    { lat: 25.101040789, lng: 121.533547437 },
    { lat: 25.101010209, lng: 121.533373895 },
    { lat: 25.100943281, lng: 121.533150775 },
    { lat: 25.100926746, lng: 121.532769959 },
    { lat: 25.100890292, lng: 121.532468445 },
    { lat: 25.10084895, lng: 121.532185519 },
    { lat: 25.100687584, lng: 121.531768144 },
    { lat: 25.100548019, lng: 121.531475666 },
    { lat: 25.100400775, lng: 121.531163934 },
    { lat: 25.100241763, lng: 121.53082923 },
    { lat: 25.100115627, lng: 121.530469289 },
    { lat: 25.100042425, lng: 121.530182256 },
    { lat: 25.100064683, lng: 121.529950957 },
    { lat: 25.100281861, lng: 121.529743848 },
    { lat: 25.100357571, lng: 121.529641938 },
    { lat: 25.100433986, lng: 121.529280436 },
    { lat: 25.100541048, lng: 121.528855106 },
    { lat: 25.100651591, lng: 121.528402518 },
    { lat: 25.100703725, lng: 121.52819153 },
    { lat: 25.100712287, lng: 121.527909723 },
    { lat: 25.100680923, lng: 121.527350416 },
    { lat: 25.100644068, lng: 121.527050041 },
    { lat: 25.100600884, lng: 121.526818171 },
    { lat: 25.100564803, lng: 121.526648231 },
    { lat: 25.100564245, lng: 121.526219771 },
    { lat: 25.100495494, lng: 121.52567521 },
    { lat: 25.10042491, lng: 121.525302132 },
    { lat: 25.100332024, lng: 121.524969253 },
    { lat: 25.100223119, lng: 121.524676215 },
    { lat: 25.100045311, lng: 121.524390485 },
    { lat: 25.099865065, lng: 121.524125151 },
    { lat: 25.099669237, lng: 121.523951907 },
    { lat: 25.099493627, lng: 121.523736999 },
    { lat: 25.09936326, lng: 121.523524518 },
    { lat: 25.09920533, lng: 121.523349067 },
    { lat: 25.099047069, lng: 121.522960035 },
    { lat: 25.098933397, lng: 121.522518545 },
    { lat: 25.098853935, lng: 121.522173248 },
    { lat: 25.098793906, lng: 121.52174999 },
    { lat: 25.098825433, lng: 121.521526372 },
    { lat: 25.098929503, lng: 121.521364933 },
    { lat: 25.098966254, lng: 121.521212597 },
    { lat: 25.09896111, lng: 121.520999077 },
    { lat: 25.099129172, lng: 121.520685067 },
    { lat: 25.099387079, lng: 121.520245358 },
    { lat: 25.099582228, lng: 121.519904709 },
    { lat: 25.099658212, lng: 121.519766854 },
    { lat: 25.099771629, lng: 121.519671158 },
    { lat: 25.099850954, lng: 121.519299137 },
    { lat: 25.099796804, lng: 121.519216913 },
    { lat: 25.099828927, lng: 121.518773813 },
    { lat: 25.099905707, lng: 121.51867378 },
    { lat: 25.099947608, lng: 121.518267752 },
    { lat: 25.099928792, lng: 121.517829787 },
    { lat: 25.099902138, lng: 121.517440072 },
    { lat: 25.099824713, lng: 121.516835156 },
    { lat: 25.099796309, lng: 121.516450003 },
    { lat: 25.099722968, lng: 121.515824069 },
    { lat: 25.099594267, lng: 121.515147611 },
    { lat: 25.099468578, lng: 121.514681055 },
    { lat: 25.099258018, lng: 121.514073049 },
    { lat: 25.099147676, lng: 121.513781201 },
    { lat: 25.098973866, lng: 121.513281659 },
    { lat: 25.098921991, lng: 121.513123917 },
  ],
  // Route A_2
  [TYPE.BIKE_BYE]: [
    // { lat: 25.096414352, lng: 121.515451785 },
    // { lat: 25.096169516, lng: 121.514157889 },
    // { lat: 25.097969458, lng: 121.51350641 },
    // { lat: 25.098164379, lng: 121.513929693 },
    // { lat: 25.098318355, lng: 121.514223254 },
    // { lat: 25.098410402, lng: 121.514401498 },
    // { lat: 25.098464355, lng: 121.514438495 },
    // { lat: 25.098385071, lng: 121.514202432 },
    // { lat: 25.098256977, lng: 121.513831032 },
    // { lat: 25.098150918, lng: 121.51346613 },
    // { lat: 25.097921079, lng: 121.512834323 },
    // { lat: 25.097626844, lng: 121.511956216 },
    // { lat: 25.097465886, lng: 121.511459445 },
    // { lat: 25.097337258, lng: 121.511019623 },
    // { lat: 25.097155616, lng: 121.510765219 },
    { lat: 25.096954547, lng: 121.510611896 },
    { lat: 25.0967528, lng: 121.510530879 },
    { lat: 25.096485892, lng: 121.510564865 },
    { lat: 25.096290861, lng: 121.510623019 },
    { lat: 25.09606642, lng: 121.510535274 },
    { lat: 25.095813277, lng: 121.510378259 },
    { lat: 25.095762676, lng: 121.510370346 },
    { lat: 25.095672052, lng: 121.510460444 },
    { lat: 25.095566649, lng: 121.510585947 },
    { lat: 25.095118893, lng: 121.510828662 },
    { lat: 25.095002345, lng: 121.51091793 },
    { lat: 25.094838766, lng: 121.511111664 },
    { lat: 25.094933587, lng: 121.511377568 },
    { lat: 25.095040888, lng: 121.511653197 },
    { lat: 25.095110167, lng: 121.511851523 },
    { lat: 25.094932546, lng: 121.512060904 },
    { lat: 25.094744486, lng: 121.512282004 },
    { lat: 25.09444714, lng: 121.512601928 },
    { lat: 25.094068301, lng: 121.51294667 },
    { lat: 25.093736395, lng: 121.513230738 },
    { lat: 25.093448052, lng: 121.513429783 },
    { lat: 25.092891273, lng: 121.51382422 },
    { lat: 25.09256061, lng: 121.51406838 },
    { lat: 25.092176921, lng: 121.514252971 },
    { lat: 25.091531143, lng: 121.514557787 },
    { lat: 25.091239522, lng: 121.514686288 },
    { lat: 25.091091808, lng: 121.514686201 },
    { lat: 25.090821988, lng: 121.514826916 },
    { lat: 25.090189097, lng: 121.514990695 },
    { lat: 25.089453267, lng: 121.515135099 },
    { lat: 25.088740633, lng: 121.515276774 },
    { lat: 25.088285946, lng: 121.515330711 },
    { lat: 25.087630925, lng: 121.51542964 },
    { lat: 25.08728519, lng: 121.515472123 },
    { lat: 25.08698026, lng: 121.515476918 },
    { lat: 25.086246619, lng: 121.515561329 },
    { lat: 25.085649043, lng: 121.515652822 },
    { lat: 25.085455186, lng: 121.51571041 },
    { lat: 25.085393062, lng: 121.515751021 },
    { lat: 25.084802796, lng: 121.515826229 },
    { lat: 25.084105171, lng: 121.515966663 },
    { lat: 25.083560263, lng: 121.516130386 },
    { lat: 25.083008879, lng: 121.516369514 },
    { lat: 25.082577276, lng: 121.516551501 },
    { lat: 25.082301811, lng: 121.516633821 },
    { lat: 25.081659785, lng: 121.516994893 },
    { lat: 25.081636445, lng: 121.516879906 },
    { lat: 25.081527445, lng: 121.516848262 },
    { lat: 25.081449286, lng: 121.516889631 },
    { lat: 25.081432783, lng: 121.517143796 },
    { lat: 25.080943042, lng: 121.517530495 },
    { lat: 25.080745768, lng: 121.517715035 },
    { lat: 25.080694469, lng: 121.517940421 },
    { lat: 25.080221564, lng: 121.518462879 },
    { lat: 25.079840827, lng: 121.518931481 },
    { lat: 25.079563846, lng: 121.519378065 },
    { lat: 25.079204045, lng: 121.519956328 },
    { lat: 25.078637622, lng: 121.521025351 },
    { lat: 25.078576485, lng: 121.521092274 },
    { lat: 25.078498637, lng: 121.521098853 },
    { lat: 25.07843633, lng: 121.52144606 },
    { lat: 25.078251499, lng: 121.52176363 },
    { lat: 25.077809591, lng: 121.522428527 },
    { lat: 25.077577822, lng: 121.522823122 },
    { lat: 25.077180689, lng: 121.52344223 },
    { lat: 25.076744104, lng: 121.523976626 },
    { lat: 25.076326014, lng: 121.524322497 },
    { lat: 25.075680965, lng: 121.524771423 },
    { lat: 25.075093456, lng: 121.525113771 },
    { lat: 25.074651259, lng: 121.525367476 },
    { lat: 25.074484672, lng: 121.525440941 },
    { lat: 25.074361121, lng: 121.525285208 },
    { lat: 25.073836036, lng: 121.524420581 },
    { lat: 25.073950714, lng: 121.52453048 },
    { lat: 25.074496939, lng: 121.52425108 },
    { lat: 25.075244399, lng: 121.523715705 },
    { lat: 25.075546636, lng: 121.523443543 },
    { lat: 25.075594255, lng: 121.522980978 },
    { lat: 25.075765091, lng: 121.522725427 },
    { lat: 25.075823664, lng: 121.522422236 },
    { lat: 25.075685314, lng: 121.522068155 },
    { lat: 25.075395514, lng: 121.52185748 },
    { lat: 25.075258755, lng: 121.521832355 },
    { lat: 25.075101958, lng: 121.521902779 },
    { lat: 25.074919938, lng: 121.522034571 },
    { lat: 25.074809728, lng: 121.521954438 },
    { lat: 25.074722343, lng: 121.521807784 },
    { lat: 25.074798259, lng: 121.52149249 },
    { lat: 25.074841886, lng: 121.521347833 },
    { lat: 25.074612568, lng: 121.521175699 },
    { lat: 25.074063854, lng: 121.520974609 },
    { lat: 25.073446859, lng: 121.520703658 },
    { lat: 25.072856699, lng: 121.520598363 },
    { lat: 25.071925623, lng: 121.520446612 },
    { lat: 25.071232209, lng: 121.520381376 },
  ],
  // Route B_1
  [TYPE.WALKING_CHI]: [
    { lat: 25.102663217, lng: 121.530175571 },
    { lat: 25.102513185, lng: 121.530347792 },
    { lat: 25.102361481, lng: 121.530535844 },
    { lat: 25.102381631, lng: 121.53087306 },
    { lat: 25.102420192, lng: 121.531076879 },
    { lat: 25.10234488, lng: 121.531134514 },
    { lat: 25.102287712, lng: 121.531200468 },
    { lat: 25.102335218, lng: 121.531526799 },
    { lat: 25.102254961, lng: 121.531540678 },
    { lat: 25.102059431, lng: 121.531538843 },
    { lat: 25.102015978, lng: 121.53155402 },
    { lat: 25.10207947, lng: 121.531687533 },
    { lat: 25.102078083, lng: 121.531819795 },
    { lat: 25.102062048, lng: 121.53198081 },
    { lat: 25.102148554, lng: 121.532231123 },
    { lat: 25.10213017, lng: 121.532301372 },
    { lat: 25.102054506, lng: 121.532414116 },
    { lat: 25.101896317, lng: 121.532514689 },
    { lat: 25.10179511, lng: 121.532621533 },
    { lat: 25.101746166, lng: 121.532865821 },
    { lat: 25.101712664, lng: 121.532929788 },
    { lat: 25.101668563, lng: 121.533063722 },
    { lat: 25.101687059, lng: 121.533124303 },
    { lat: 25.10165682, lng: 121.533229897 },
    { lat: 25.101676309, lng: 121.533391725 },
    { lat: 25.101786186, lng: 121.533413122 },
    { lat: 25.102053268, lng: 121.533315101 },
    { lat: 25.102376007, lng: 121.533270241 },
    { lat: 25.102710649, lng: 121.533313443 },
    { lat: 25.102916507, lng: 121.533359804 },
    { lat: 25.103063616, lng: 121.533374854 },
    { lat: 25.103153877, lng: 121.533415844 },
    { lat: 25.103197558, lng: 121.53346006 },
    { lat: 25.102968381, lng: 121.533473191 },
    { lat: 25.102848623, lng: 121.533521169 },
    { lat: 25.102853415, lng: 121.533571626 },
    { lat: 25.10315849, lng: 121.533574164 },
    { lat: 25.103449288, lng: 121.533531962 },
    { lat: 25.10372945, lng: 121.53340657 },
    { lat: 25.104019961, lng: 121.533175873 },
    { lat: 25.104210563, lng: 121.532876215 },
    { lat: 25.104304207, lng: 121.532576617 },
    { lat: 25.104360926, lng: 121.532222544 },
    { lat: 25.104417613, lng: 121.531843456 },
    { lat: 25.104498506, lng: 121.53149474 },
    { lat: 25.104549967, lng: 121.53115739 },
    { lat: 25.10436862, lng: 121.531184262 },
    { lat: 25.104349011, lng: 121.531100025 },
    { lat: 25.104284431, lng: 121.530894749 },
    { lat: 25.104179157, lng: 121.530828305 },
    { lat: 25.104079343, lng: 121.530582606 },
    { lat: 25.104053439, lng: 121.530515293 },
    { lat: 25.104070616, lng: 121.530191315 },
    { lat: 25.104081154, lng: 121.529984623 },
    { lat: 25.104206508, lng: 121.529774889 },
    { lat: 25.10426425, lng: 121.529604867 },
    { lat: 25.104107265, lng: 121.52932981 },
    { lat: 25.10397762, lng: 121.529074601 },
    { lat: 25.103746429, lng: 121.529023056 },
    { lat: 25.103581971, lng: 121.529020309 },
    { lat: 25.103441665, lng: 121.529098231 },
    { lat: 25.103278265, lng: 121.52924221 },
    { lat: 25.103093563, lng: 121.529443762 },
    { lat: 25.103024616, lng: 121.529531665 },
    { lat: 25.103038454, lng: 121.529635045 },
    { lat: 25.102993315, lng: 121.529755148 },
    { lat: 25.102836605, lng: 121.529910363 },
    { lat: 25.102742651, lng: 121.529984111 },
    { lat: 25.102663217, lng: 121.530175571 },
  ],
  // Route B_2
  [TYPE.WALKING_PO]: [
    { lat: 25.100222, lng: 121.550434 },
    { lat: 25.100311, lng: 121.55057 },
    { lat: 25.10055, lng: 121.550936 },
    { lat: 25.100403, lng: 121.551077 },
    { lat: 25.100138, lng: 121.551452 },
    { lat: 25.099914, lng: 121.551779 },
    { lat: 25.099888, lng: 121.551816 },
    { lat: 25.099822, lng: 121.551909 },
    { lat: 25.100515, lng: 121.55246 },
    { lat: 25.100675, lng: 121.552634 },
    { lat: 25.100715, lng: 121.552734 },
    { lat: 25.100709, lng: 121.552843 },
    { lat: 25.100747, lng: 121.552907 },
    { lat: 25.100758, lng: 121.552999 },
    { lat: 25.100891, lng: 121.553328 },
    { lat: 25.100948, lng: 121.553408 },
    { lat: 25.101002, lng: 121.553587 },
    { lat: 25.101062, lng: 121.553788 },
    { lat: 25.101039, lng: 121.553866 },
    { lat: 25.101013, lng: 121.554114 },
    { lat: 25.101064, lng: 121.55422 },
    { lat: 25.101065, lng: 121.554283 },
    { lat: 25.101063, lng: 121.554289 },
    { lat: 25.101035, lng: 121.554387 },
    { lat: 25.101048, lng: 121.554433 },
    { lat: 25.101029, lng: 121.554549 },
    { lat: 25.101045, lng: 121.554645 },
    { lat: 25.101019, lng: 121.55473 },
    { lat: 25.101035, lng: 121.554776 },
    { lat: 25.101007, lng: 121.554886 },
    { lat: 25.100973, lng: 121.554953 },
    { lat: 25.100981, lng: 121.555024 },
    { lat: 25.10084, lng: 121.55514 },
    { lat: 25.100718, lng: 121.555051 },
    { lat: 25.100592, lng: 121.555053 },
    { lat: 25.100517, lng: 121.555059 },
    { lat: 25.100433, lng: 121.55509 },
    { lat: 25.100354, lng: 121.555197 },
    { lat: 25.100348, lng: 121.55526 },
    { lat: 25.100243, lng: 121.555318 },
    { lat: 25.100195, lng: 121.555424 },
    { lat: 25.100143, lng: 121.555483 },
    { lat: 25.099945, lng: 121.555619 },
    { lat: 25.099885, lng: 121.555778 },
    { lat: 25.099857, lng: 121.555824 },
    { lat: 25.099727, lng: 121.556035 },
    { lat: 25.09972, lng: 121.556045 },
    { lat: 25.099592, lng: 121.556027 },
    { lat: 25.099463, lng: 121.55602 },
    { lat: 25.099372, lng: 121.555926 },
    { lat: 25.099327, lng: 121.555807 },
    { lat: 25.099316, lng: 121.555777 },
    { lat: 25.099258, lng: 121.555656 },
    { lat: 25.099207, lng: 121.555493 },
    { lat: 25.099179, lng: 121.555329 },
    { lat: 25.09918, lng: 121.555111 },
    { lat: 25.099219, lng: 121.554947 },
    { lat: 25.099308, lng: 121.554845 },
    { lat: 25.099378, lng: 121.554761 },
    { lat: 25.099396, lng: 121.554652 },
    { lat: 25.099375, lng: 121.554536 },
    { lat: 25.099142, lng: 121.554179 },
    { lat: 25.09902, lng: 121.554073 },
    { lat: 25.098877, lng: 121.554011 },
    { lat: 25.098723, lng: 121.554022 },
    { lat: 25.098362, lng: 121.554124 },
    { lat: 25.098245, lng: 121.554119 },
    { lat: 25.097954, lng: 121.554035 },
    { lat: 25.097873, lng: 121.554038 },
    { lat: 25.097818, lng: 121.55408 },
    { lat: 25.097765, lng: 121.554201 },
    { lat: 25.097651, lng: 121.554346 },
    { lat: 25.097546, lng: 121.554428 },
    { lat: 25.097473, lng: 121.554484 },
    { lat: 25.097195, lng: 121.554584 },
    { lat: 25.097027, lng: 121.554624 },
    { lat: 25.096962, lng: 121.554612 },
    { lat: 25.096943, lng: 121.554609 },
    { lat: 25.09694, lng: 121.55462 },
    { lat: 25.096982, lng: 121.554903 },
    { lat: 25.097071, lng: 121.555155 },
    { lat: 25.097129, lng: 121.555237 },
    { lat: 25.097194, lng: 121.555286 },
    { lat: 25.097252, lng: 121.555424 },
    { lat: 25.097346, lng: 121.55557 },
    { lat: 25.097409, lng: 121.55562 },
    { lat: 25.097441, lng: 121.55567 },
    { lat: 25.097489, lng: 121.555791 },
    { lat: 25.097488, lng: 121.556227 },
    { lat: 25.097468, lng: 121.556321 },
    { lat: 25.097475, lng: 121.556404 },
    { lat: 25.09753, lng: 121.556585 },
    { lat: 25.097527, lng: 121.55681 },
    { lat: 25.097595, lng: 121.556988 },
    { lat: 25.097607, lng: 121.557043 },
    { lat: 25.097614, lng: 121.557073 },
    { lat: 25.097746, lng: 121.557046 },
    { lat: 25.097978, lng: 121.556971 },
    { lat: 25.098249, lng: 121.556908 },
    { lat: 25.098401, lng: 121.556898 },
    { lat: 25.098608, lng: 121.556816 },
    { lat: 25.098675, lng: 121.556851 },
    { lat: 25.098731, lng: 121.556838 },
    { lat: 25.098796, lng: 121.556771 },
    { lat: 25.098969, lng: 121.556513 },
    { lat: 25.099059, lng: 121.556416 },
    { lat: 25.09914, lng: 121.556319 },
    { lat: 25.099167, lng: 121.556257 },
    { lat: 25.099369, lng: 121.55593 },
    { lat: 25.099372, lng: 121.555926 },
    { lat: 25.099463, lng: 121.55602 },
    { lat: 25.099592, lng: 121.556027 },
    { lat: 25.09972, lng: 121.556045 },
    { lat: 25.099727, lng: 121.556035 },
    { lat: 25.099857, lng: 121.555824 },
    { lat: 25.099885, lng: 121.555778 },
    { lat: 25.099945, lng: 121.555619 },
    { lat: 25.100143, lng: 121.555483 },
    { lat: 25.100195, lng: 121.555424 },
    { lat: 25.100243, lng: 121.555318 },
    { lat: 25.100348, lng: 121.55526 },
    { lat: 25.100354, lng: 121.555197 },
    { lat: 25.100433, lng: 121.55509 },
    { lat: 25.100517, lng: 121.555059 },
    { lat: 25.100592, lng: 121.555053 },
    { lat: 25.100718, lng: 121.555051 },
    { lat: 25.10084, lng: 121.55514 },
    { lat: 25.100981, lng: 121.555024 },
    { lat: 25.100973, lng: 121.554953 },
    { lat: 25.101007, lng: 121.554886 },
    { lat: 25.101035, lng: 121.554776 },
    { lat: 25.101019, lng: 121.55473 },
    { lat: 25.101045, lng: 121.554645 },
    { lat: 25.101029, lng: 121.554549 },
    { lat: 25.101048, lng: 121.554433 },
    { lat: 25.101035, lng: 121.554387 },
    { lat: 25.101063, lng: 121.554289 },
    { lat: 25.101065, lng: 121.554283 },
    { lat: 25.101064, lng: 121.55422 },
    { lat: 25.101013, lng: 121.554114 },
    { lat: 25.101037, lng: 121.553881 },
    { lat: 25.101039, lng: 121.553866 },
    { lat: 25.101062, lng: 121.553788 },
    { lat: 25.100948, lng: 121.553408 },
    { lat: 25.100891, lng: 121.553328 },
    { lat: 25.100758, lng: 121.552999 },
    { lat: 25.100747, lng: 121.552907 },
    { lat: 25.100709, lng: 121.552843 },
    { lat: 25.100715, lng: 121.552734 },
    { lat: 25.100675, lng: 121.552634 },
    { lat: 25.100515, lng: 121.55246 },
    { lat: 25.099822, lng: 121.551909 },
    { lat: 25.099888, lng: 121.551816 },
    { lat: 25.099914, lng: 121.551779 },
    { lat: 25.100138, lng: 121.551452 },
    { lat: 25.100403, lng: 121.551077 },
    { lat: 25.10055, lng: 121.550936 },
    { lat: 25.100311, lng: 121.55057 },
    { lat: 25.100262, lng: 121.550495 },
  ],
  // Route C_1
  [TYPE.HIKING_JIAN]: [
    { lat: 25.085879906, lng: 121.537463159 },
    { lat: 25.086110122, lng: 121.537252151 },
    { lat: 25.086540876, lng: 121.537207127 },
    { lat: 25.086762267, lng: 121.537161316 },
    { lat: 25.087034058, lng: 121.537131665 },
    { lat: 25.087169821, lng: 121.537241876 },
    { lat: 25.087568766, lng: 121.537368454 },
    { lat: 25.087994305, lng: 121.537420263 },
    { lat: 25.088172413, lng: 121.53737607 },
    { lat: 25.088379834, lng: 121.537298344 },
    { lat: 25.088712314, lng: 121.537379395 },
    { lat: 25.088846756, lng: 121.537414062 },
    { lat: 25.088931713, lng: 121.53734204 },
    { lat: 25.089058871, lng: 121.537320952 },
    { lat: 25.089137809, lng: 121.537494228 },
    { lat: 25.089286506, lng: 121.537606401 },
    { lat: 25.089542062, lng: 121.537601947 },
    { lat: 25.089969453, lng: 121.537544345 },
    { lat: 25.090371482, lng: 121.537864888 },
    { lat: 25.090633787, lng: 121.538319941 },
    { lat: 25.090801762, lng: 121.538632423 },
    { lat: 25.09089483, lng: 121.538776779 },
    { lat: 25.090934356, lng: 121.538514599 },
    { lat: 25.090845298, lng: 121.537992648 },
    { lat: 25.090736446, lng: 121.537523126 },
    { lat: 25.090734683, lng: 121.537230901 },
    { lat: 25.090767576, lng: 121.537075208 },
    { lat: 25.090707674, lng: 121.536973149 },
    { lat: 25.090620549, lng: 121.53684048 },
    { lat: 25.090441597, lng: 121.536744257 },
    { lat: 25.090184401, lng: 121.536107125 },
    { lat: 25.089815602, lng: 121.535662795 },
    { lat: 25.089052178, lng: 121.534855317 },
    { lat: 25.088017266, lng: 121.53390657 },
    { lat: 25.087984791, lng: 121.533678903 },
    { lat: 25.087803515, lng: 121.533417613 },
    { lat: 25.087708479, lng: 121.532872165 },
    { lat: 25.086917509, lng: 121.53253079 },
    { lat: 25.086480889, lng: 121.532003249 },
    { lat: 25.086058052, lng: 121.531600885 },
    { lat: 25.085791849, lng: 121.53136813 },
    { lat: 25.085384889, lng: 121.531262017 },
    { lat: 25.085073782, lng: 121.531032241 },
    { lat: 25.085044771, lng: 121.530648651 },
    { lat: 25.085092291, lng: 121.530481829 },
    { lat: 25.084838875, lng: 121.530382632 },
    { lat: 25.084579211, lng: 121.530050459 },
    { lat: 25.084332944, lng: 121.529668505 },
    { lat: 25.084260189, lng: 121.529436291 },
    { lat: 25.084286102, lng: 121.529291654 },
  ],
};

export default routes;
