import React, { Suspense, lazy, useState, useCallback, useEffect } from "react";
import Link from "./LinkWithTransitionLock";
import { isMobile } from "mobile-device-detect";
import Helmet from "react-helmet";
import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import { Slide, Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import SelectBuilding from "./SelectBuilding";
import g from "gsap-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";

const fadeImages = [
  require("../images/planeasyfloor/vr-1-1.jpeg"),
  require("../images/planeasyfloor/vr-1-2.jpeg"),
  require("../images/planeasyfloor/vr-2-1.jpeg"),
  require("../images/planeasyfloor/vr-2-2.jpeg"),
  require("../images/planeasyfloor/vr-3-1.jpeg"),
  require("../images/planeasyfloor/vr-3-2.jpeg"),
  require("../images/planeasyfloor/vr-4-1.jpeg"),
  require("../images/planeasyfloor/vr-4-2.jpeg"),
  require("../images/planeasyfloor/vr-5-1.jpeg"),
  require("../images/planeasyfloor/vr-5-2.jpeg"),
  require("../images/planeasyfloor/vr-5-3.jpeg"),
  require("../images/empty/1.jpeg"),
  require("../images/empty/2.jpeg"),
  require("../images/empty/3_2.jpeg"),
  require("../images/empty/3_3.jpeg"),
  require("../images/empty/4.jpeg"),
  // require("../images/planeasyfloor/vr-0-2.jpg"),
  // require("../images/planeasyfloor/vr-0-3.jpg"),
];

const properties = {
  duration: 3000,
  transitionDuration: 500,
  infinite: true,
  canSwipe: true,
  indicators: true,
  prevArrow: (
    <div className="slide_nav prev">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="arrow"
        fill="#fff"
      >
        <path d="M5.25514 11.0138H15.7106L10.9124 6.21565L12.1281 5L19.001 11.8729L12.1281 18.7458L10.9124 17.5302L15.7106 12.732H5.25514V11.0138Z"></path>
      </svg>
    </div>
  ),
  nextArrow: (
    <div className="slide_nav next">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className="arrow"
        fill="#fff"
      >
        <path d="M5.25514 11.0138H15.7106L10.9124 6.21565L12.1281 5L19.001 11.8729L12.1281 18.7458L10.9124 17.5302L15.7106 12.732H5.25514V11.0138Z"></path>
      </svg>
    </div>
  ),
  // indicators: (i) => <div className="indicator">{i + 1}</div>,
};

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);

    var header = document.querySelector(".header");
    header.classList.remove("hide");
    header.classList.remove("inner");
    document.querySelector("body").classList.remove("overflow");

    var window_width = window.innerWidth;
    var window_height = window.innerHeight;

    const cover_video = document.querySelectorAll(".inner_cover.video");

    if (window_width < 767) {
      cover_video.forEach(function(element) {
        element.style.height = window_width + "px";
      });
    } else {
      cover_video.forEach(function(element) {
        element.style.height = window_height * 0.8 + "px";
      });
    }

    gsap.to(".section_a .inner_cover .video", {
      yPercent: 50,
      // opacity: 0,
      ease: "none",
      scrollTrigger: {
        trigger: ".section_b",
        start: "top bottom",
        end: "center top",
        scrub: true,
      },
    });

    gsap.from(".page_title .text_a", {
      y: "100%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
      delay: 0.8,
    });
    gsap.from(".page_title .text_b", {
      y: "100%",
      opacity: 0,
      duration: 1.5,
      ease: Power4.easeOut,
      delay: 1.5,
    });

    gsap.to(".select_floor", {
      scrollTrigger: {
        trigger: ".section_d .inner_content",
        start: "top",
        end: "+=" + window.innerHeight * 2,
        scrub: true,
        // markers: true,
      },
      display: "block",
    });
    gsap.to(".select_floor .building_photo", {
      scrollTrigger: {
        trigger: ".section_d .inner_content",
        start: "top",
        end: "+=" + window.innerHeight * 2,
        scrub: true,
        // markers: true,
      },
      opacity: 1,
    });

    var line = document.querySelectorAll(".line");

    var tl = new TimelineMax({
      onComplete: function() {
        tl.restart();
      },
    });

    TweenLite.defaultEase = Power4.easeInOut;

    var time = 0.9;
    var y = 20;

    tl.add(
      TweenMax.staggerFromTo(
        line,
        1,
        {
          opacity: 0,
          y: y,
        },
        {
          opacity: 1,
          y: 0,
        },
        4
      )
    ).add(
      TweenMax.staggerTo(
        line,
        1,
        {
          // delay: 2,
          opacity: 0,
          y: "-20px",
        },
        4
      ),
      4
    );

    window.addEventListener("scroll", function() {
      var section = document.querySelectorAll("section");
      section.forEach(function(element) {
        if (element.getBoundingClientRect().y - window_height * 0.7 < 0) {
          element.classList.add("enter");
        } else {
          element.classList.remove("enter");
        }
      });
    });
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        <section className="section_container section_a dark">
          <div className="inner_cover video">
            <h2 className="page_title">
              <div className="text text_a">自由平面</div>
              <div className="text text_b">
                <div className="line">自由平面，全齡彈性空間</div>
                <div className="line">個性築家，心自在其間</div>
                <div className="line">
                  室內公共空間置於中央，創造各種使用的可能
                </div>
              </div>
            </h2>
            <video
              className="video"
              autoPlay
              loop
              playsInline
              muted="muted"
              src={require("../videos/inside_video_main_s2.mp4")}
            ></video>
          </div>
          <div className="inner_content">
            <article className="row_area_ms">
              <div className="col_1 title">
                <h3>公領域隨心變化</h3>
              </div>
              <div className="col_1 context">
                <p>
                  打破既有對廳房的想像，讓結合客廳、餐廳、廚房的多功能空間置於中央，房間則環繞四周。
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="section_container section_b light">
          <div className="inner_cover video">
            <video
              className="video"
              autoPlay
              loop
              playsInline
              muted="muted"
              src={require("../videos/inside_video_3.mp4")}
            ></video>
          </div>
          <div className="inner_content">
            <article className="row_area_ms">
              <div className="col_1 title">
                <h3>無拘格局，公共空間方正開闊</h3>
              </div>
              <div className="col_1 context">
                <p>
                  下雨的日子，待在家裡泡杯咖啡，欣賞好不容易決定擺設位置的畫作，把玩一下剛入手的相機鏡頭。房廳不分置兩端，而是讓房間圍繞大客廳，走出房門看見彼此，待在大客廳談天說地，相聚即日常。
                </p>
              </div>
            </article>
          </div>
        </section>

        <section className="section_container section_c dark">
          <div className="inner_cover">
            <div className="slide-container">
              <Fade {...properties}>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[11]} />
                  </div>
                  {/* <h2>1-1</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[0]} />
                  </div>
                  {/* <h2>1-1</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[1]} />
                  </div>
                  {/* <h2>1-2</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[13]} />
                  </div>
                  {/* <h2>3-1</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[2]} />
                  </div>
                  {/* <h2>2-1</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[3]} />
                  </div>
                  {/* <h2>2-2</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[9]} />
                  </div>
                  {/* <h2>3-1</h2> */}
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[10]} />
                  </div>
                  {/* <h2>4-1</h2> */}
                </div>
              </Fade>
            </div>
          </div>
          <div className="inner_content">
            <article className="row_area_ms">
              <div className="col_1 title">
                <h3>寬大格局，感性兌現</h3>
              </div>
              <div className="col_1 context">
                <p>
                  大宅內公共空間的比例和完整性，住戶可依需求，自由變更使用目的，作為聚餐、辦公空間、甚至室內運動的場域；另一方面，也能鼓勵同住的家人和朋友，來到公共空間進行交流，培養彼此的感情。
                </p>
              </div>
            </article>
            {/* <div className="slide-container">
              <Fade {...properties}>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[15]} />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[4]} />
                  </div>
                </div>
                <div className="each-fade">
                  <div className="image-container">
                    <img src={fadeImages[5]} />
                  </div>
                </div>
              </Fade>
            </div> */}
          </div>
        </section>

        <section className="section_container section_d dark">
          <div className="inner_cover video">
            <video
              className="video"
              autoPlay
              loop
              playsInline
              muted="muted"
              src={require("../videos/inside_video_2s.mp4")}
            ></video>
          </div>
        </section>

        <SelectBuilding />

        <Helmet
          title="自由平面・璞園知山・知己然後知山・璞園建築團隊"
          meta={[
            {
              name: `description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:title`,
              content: "自由平面・璞園知山・知己然後知山・璞園建築團隊",
            },
            {
              property: `og:description`,
              content:
                "一層一戶，獨享廣袤天地。自由平面，全齡彈性空間。開闊面寬，悠遊群山綠水。謐光環境，匠心獨具作品。璞園建築團隊，精工細作。",
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              // content:
              // "https://online.home-to-all.com" +
              // require("../images/422socialimage.jpg"),
            },
          ]}
        ></Helmet>
      </>
    );
  }
}

export default Page;
